<template>
	<div class="row d-flex flex-column flex-md-row">
		<div class="col-12 col-md-3">
			<select
				v-model="currentFirstFilter"
				class="form-select selector-servicio text-primary translate"
				:disabled="!isServicioDescontratable(reserva, servicioWithCantidadSeleccionado.servicio)"
				@change="firstFilterChangeHandler">
				<option :value="null">
					{{ servicioNulo.nombre }}
					<template v-if="servicioNulo.precio"> ({{ servicioNulo.precio }}{{ agenciaStore.getCurrency }}) </template>
				</option>
				<option v-for="firstFilter in filtros.firstFilter" :key="firstFilter.id" :value="firstFilter">
					{{ firstFilter.nombre }}
				</option>
			</select>
		</div>
		<div v-if="currentFirstFilter" class="col-12 col-md-3 mt-2 mt-md-0">
			<select
				v-model="currentSecondFilter"
				class="form-select text-primary translate selector-servicio"
				:disabled="!isServicioDescontratable(reserva, servicioWithCantidadSeleccionado.servicio)"
				@change="secondFilterChangeHandler">
				<option :value="null" selected>
					{{ $t('filtroModalidades.titulo.seleccionaGama') }}
				</option>
				<option v-for="secondFilter in currentFirstFilter?.secondFilter" :key="secondFilter.id" :value="secondFilter">
					{{ secondFilter.nombre }}
				</option>
			</select>
		</div>
		<div v-if="currentSecondFilter" class="col-12 col-md-6 mt-2 mt-md-0">
			<div class="input-group gap-3">
				<div class="select-container">
					<select
						:value="servicioWithCantidadSeleccionado.servicio.id"
						class="form-select selector-servicio text-primary w-100 translate"
						:disabled="!isServicioDescontratable(reserva, servicioWithCantidadSeleccionado.servicio)"
						@change="handleServicioSeleccionadoChange">
						<option :key="servicioNulo.id" :value="servicioNulo.id">
							{{ $t('filtroModalidades.titulo.seleccionaServicio') }}
						</option>
						<option
							v-for="servicio in serviciosFiltrados
								.filter(s => s.activo || isServicioSeleccionado(s))
								.sort((s1, s2) => s1.precio - s2.precio)"
							:key="servicio.id"
							:value="servicio.id"
							:disabled="!isServicioSeleccionable(servicio)">
							{{ servicio.nombre }} ({{ $t('general.del') }}
							{{ formatDateDDmm(servicio.fechaInicio) }}
							{{ $t('general.al') }} {{ formatDateDDmm(servicio.fechaFin) }})
							<template v-if="servicio.precio && isServicioSeleccionable(servicio)">
								(<template v-if="servicio.precio > 0">+</template>{{ servicio.precio }}{{ agenciaStore.getCurrency }})
							</template>
							<template v-if="!isServicioSeleccionable(servicio)">({{ $t('general.agotado') }})</template>
						</option>
					</select>
				</div>
				<div class="d-flex align-items-center">
					<div class="cantidad-container col-12 col-md-12 ml-6">
						<template v-if="!servicioWithCantidadSeleccionado.servicio.nominal">
							<InputSelectorCantidad
								:disabled="!isServicioContratable(reserva, servicioWithCantidadSeleccionado.servicio)"
								:count="servicioWithCantidadSeleccionado.cantidad"
								:min-count="
									isServicioDescontratable(reserva, servicioWithCantidadSeleccionado.servicio)
										? 1
										: servicioWithCantidadSeleccionado.cantidadOrginial
								"
								:max-count="calculaMaxCantidadForServicio(servicioWithCantidadSeleccionado.servicio)"
								:is-agotado="!isServicioSeleccionable(servicioWithCantidadSeleccionado.servicio)"
								@update-count="handleCantidadServicioSeleccionadoChange" />
						</template>
					</div>
				</div>
			</div>
		</div>
		<div class="row text-primary mb-2 description-text">
			<template v-if="!servicioWithCantidadSeleccionado.servicio.nulo">
				<div class="col-12 translate">
					{{ servicioWithCantidadSeleccionado.servicio.descripcion }}
				</div>
			</template>
		</div>
	</div>
</template>

<script setup>
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { ref, onBeforeMount, computed, reactive } from 'vue';
	import { formatDateDDmm } from '@/helpers/dateUtiles';
	import { isServicioContratable, isServicioDescontratable } from '@/helpers/serviciosUtils';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import InputSelectorCantidad from '@/components/inputs/InputSelectorCantidad';
	import { toRawDeep } from '@/helpers/reactivityUtils';

	const props = defineProps({
		idxReserva: {
			type: Number,
			default: null,
		},
		categoriaServicios: {
			type: Object,
			default: null,
		},
	});

	const emits = defineEmits(['servicioSeleccionado']);

	const storeHabitacion = informacionHabitacion();
	const agenciaStore = agenciaStoreModule();
	const reserva = computed(() => storeHabitacion.getReservaByIdx(props.idxReserva));
	const filtros = computed(() => storeHabitacion.filtros[props.categoriaServicios.categoria]);
	const servicioNulo = computed(() => props.categoriaServicios.servicios.find(({ nulo }) => nulo));

	const serviciosFiltrados = computed(() =>
		props.categoriaServicios.servicios.filter(
			servicio =>
				servicio.firstFilter.id === currentFirstFilter.value?.id &&
				servicio.secondFilter.id === currentSecondFilter.value?.id &&
				!servicio.nulo
		)
	);

	const currentFirstFilter = ref(null);
	const currentSecondFilter = ref(null);
	const servicioWithCantidadSeleccionado = reactive({ servicio: servicioNulo.value, cantidad: 1, oldCantidad: 1 });

	function isServicioSeleccionado(servicio) {
		return servicioWithCantidadSeleccionado?.servicio.id === servicio.id;
	}

	function isServicioSeleccionable(servicio) {
		return (
			isServicioContratable(reserva.value, servicio) &&
			(isServicioSeleccionado(servicio) || servicio.nulo || calculaMaxCantidadForServicio(servicio) > 0)
		);
	}

	function calculaMaxRealCantidadForServicio(servicio) {
		let cantidadActual = 0;
		const currentServicioCategorizado = findServicioCategorizado(servicio.id);
		if (
			servicio.cuposMetasId?.some(cupoId => servicioWithCantidadSeleccionado.servicio.cuposMetasId?.includes(cupoId))
		) {
			cantidadActual = servicioWithCantidadSeleccionado.cantidad;
		}
		return cantidadActual + currentServicioCategorizado.numCupos;
	}

	function calculaMaxCantidadForServicio(servicio) {
		return Math.min(calculaMaxRealCantidadForServicio(servicio), 10);
	}

	function initForPaqueteBasico(servicioBasico) {
		initFiltersForServicio(servicioBasico);
		servicioWithCantidadSeleccionado.servicio = servicioBasico;
		servicioWithCantidadSeleccionado.oldServicio = servicioBasico;
		servicioWithCantidadSeleccionado.cantidad = 1;
		servicioWithCantidadSeleccionado.oldCantidad = 1;
		servicioWithCantidadSeleccionado.cantidadOrginial = 1;
		servicioWithCantidadSeleccionado.categoriaPaqueteBasico = true;
		servicioWithCantidadSeleccionado.servicioPB = servicioBasico;
	}

	function initForReservaCurrentServicio(reservaCurrentServicioWithCantidad) {
		const currentServicioCategorizado = findServicioCategorizado(reservaCurrentServicioWithCantidad.servicio.id);
		const servicioPB = props.categoriaServicios.servicios.find(({ paqueteBasico }) => paqueteBasico) || null;
		if (!currentServicioCategorizado.nulo) {
			initFiltersForServicio(currentServicioCategorizado);
		}
		servicioWithCantidadSeleccionado.servicio = currentServicioCategorizado;
		servicioWithCantidadSeleccionado.oldServicio = currentServicioCategorizado;
		servicioWithCantidadSeleccionado.cantidad = reservaCurrentServicioWithCantidad.cantidad;
		servicioWithCantidadSeleccionado.oldCantidad = reservaCurrentServicioWithCantidad.cantidad;
		servicioWithCantidadSeleccionado.cantidadOrginial = reservaCurrentServicioWithCantidad.cantidad;
		servicioWithCantidadSeleccionado.categoriaPaqueteBasico = servicioPB !== null;
		servicioWithCantidadSeleccionado.servicioPB = servicioPB;
	}

	function initFiltersForServicio(servicio) {
		currentFirstFilter.value = filtros.value.firstFilter.find(({ id }) => id === servicio.firstFilter.id);
		currentSecondFilter.value = currentFirstFilter.value.secondFilter.find(({ id }) => id === servicio.secondFilter.id);
	}

	function handleCantidadServicioSeleccionadoChange(newCantidad) {
		servicioWithCantidadSeleccionado.cantidad = newCantidad;
		addServicioToReserva(true);
		if (servicioWithCantidadSeleccionado.cantidad < servicioWithCantidadSeleccionado.oldCantidad) {
			storeHabitacion.incrementCupoServicios(servicioWithCantidadSeleccionado.servicio);
		} else {
			storeHabitacion.decrementCupoServicios(servicioWithCantidadSeleccionado.servicio);
		}
		servicioWithCantidadSeleccionado.oldCantidad = newCantidad;
	}

	function handleServicioSeleccionadoChange(event) {
		servicioWithCantidadSeleccionado.servicio = toRawDeep(findServicioCategorizado(parseInt(event.target.value)));
		servicioWithCantidadSeleccionado.servicio.justSelected = true;
		servicioWithCantidadSeleccionado.cantidad = 1;
		servicioWithCantidadSeleccionado.cantidadOrginial = 1;
		addServicioToReserva(true);
		if (!servicioWithCantidadSeleccionado.servicio.nulo) {
			storeHabitacion.decrementCupoServicios(servicioWithCantidadSeleccionado.servicio);
		}
		if (!servicioWithCantidadSeleccionado.oldServicio.nulo) {
			storeHabitacion.incrementCupoServicios(
				servicioWithCantidadSeleccionado.oldServicio,
				servicioWithCantidadSeleccionado.oldCantidad
			);
		}
		servicioWithCantidadSeleccionado.oldServicio = servicioWithCantidadSeleccionado.servicio;
		servicioWithCantidadSeleccionado.oldCantidad = 1;
	}

	function addServicioToReserva(isModificacion = false) {
		storeHabitacion.addServicioToReserva(
			props.idxReserva,
			servicioWithCantidadSeleccionado.servicio,
			servicioWithCantidadSeleccionado.cantidad,
			isModificacion
		);
		emits('servicioSeleccionado', servicioWithCantidadSeleccionado);
	}

	function resetServicioSeleccionado(isModificacion = false) {
		servicioWithCantidadSeleccionado.servicio = servicioNulo.value;
		servicioWithCantidadSeleccionado.cantidad = 1;
		servicioWithCantidadSeleccionado.cantidadOriginal = 1;
		addServicioToReserva(isModificacion);
		if (servicioWithCantidadSeleccionado.oldServicio && !servicioWithCantidadSeleccionado.oldServicio.nulo) {
			storeHabitacion.incrementCupoServicios(
				servicioWithCantidadSeleccionado.oldServicio,
				servicioWithCantidadSeleccionado.oldCantidad
			);
		}
		servicioWithCantidadSeleccionado.oldServicio = servicioNulo.value;
		servicioWithCantidadSeleccionado.oldCantidad = 1;
	}

	function firstFilterChangeHandler() {
		resetServicioSeleccionado(true);
		currentSecondFilter.value = null;
	}

	function secondFilterChangeHandler() {
		resetServicioSeleccionado(true);
	}

	function findServicioCategorizado(servicioId) {
		return props.categoriaServicios.servicios.find(s => s.id === servicioId);
	}

	function init() {
		const reservaCurrentServicioWithCantidad = reserva.value?.serviciosWithCantidad[props.categoriaServicios.categoria];
		if (reservaCurrentServicioWithCantidad === undefined) {
			const servicio = props.categoriaServicios.servicios.reduce((accum, s) => {
				if ((s.paqueteBasico && s.numCupos > 0) || (accum == null && s.nulo)) {
					return s;
				} else {
					return accum;
				}
			}, null);
			if (servicio.paqueteBasico) {
				initForPaqueteBasico(servicio);
			} else {
				resetServicioSeleccionado();
			}
			storeHabitacion.decrementCupoServicios(servicioWithCantidadSeleccionado.servicio);
		} else {
			initForReservaCurrentServicio(reservaCurrentServicioWithCantidad);
		}
		addServicioToReserva();
	}

	onBeforeMount(() => init());
</script>

<style lang="scss" scoped>
	.title-card {
		background-color: #f9f7f8;
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: var(--bs-border-radius-lg);

		.text {
			display: flex;
			align-items: center;

			i {
				margin-right: 0.5rem;
			}
		}
	}

	.dot {
		height: 25px;
		width: 25px;
		background-color: var(--bs-secondary);
		border-radius: 50%;
		display: inline-block;
	}

	.dot-disabled {
		height: 25px;
		width: 25px;
		background-color: #727272;
		border-radius: 50%;
		display: inline-block;
	}

	.selector-servicio {
		background-color: rgba(var(--bs-primary-rgb), 0.02);
		box-shadow: 0 0 3px 0 rgba(var(--bs-primary-rgb), 0.35) inset;
	}

	.description-text {
		font-size: 12px;
		font-weight: 400;
		margin-top: 0.4rem;

		@media only screen and (max-width: 768px) {
			font-size: 10px;
		}
	}

	.input-group {
		display: flex;
		flex-wrap: nowrap;
		width: 100%;
		gap: 0.5rem;

		.select-container {
			flex: 1;
			min-width: 0;
			padding: 0;
		}

		.text-if-nominal {
			background-color: rgba(var(--bs-secondary-rgb), 0.35);
			font-size: 0.6rem;
		}

		> .col-9,
		> .col-3 {
			padding: 0;
		}

		@media (max-width: 768px) {
			> .col-9 {
				width: 75%;
			}
			> .col-3 {
				width: 25%;
			}
		}
	}

	.form-select {
		width: 100%;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	@media (max-width: 768px) {
		.col-12 {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
	}
</style>
