<template>
	<div class="row d-flex flex-column flex-md-row">
		<div
			v-for="(servicios, subtipo) in serviciosCategoriaBySubtipo"
			:key="subtipo"
			class="col-12 mb-3"
			:class="getClassServicio()">
			<div class="input-group gap-3 mb-1">
				<label
					v-if="categoriaServicios.categoria === 'seguro'"
					:for="'subtipo-' + subtipo"
					class="w-100 text-primary mb-2 fw-bold">
					{{ $t('servicios.seguro.subtipo.' + subtipo) }}
				</label>
				<select
					:id="'subtipo-' + subtipo"
					:value="serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio.id"
					class="form-select selector-servicio text-primary translate"
					:class="{ 'rounded-end': true }"
					:disabled="!isServicioDescontratable(reserva, serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio)"
					@change="event => handleServicioSeleccionadoChange(event, subtipo)">
					<option
						v-for="servicio in servicios
							.filter(s => s.activo || isServicioSeleccionado(s))
							.sort((s1, s2) => s1.precio - s2.precio)"
						:key="servicio.id"
						:value="servicio.id"
						:disabled="!isServicioSeleccionable(servicio)">
						<!-- PROVISIONAL: Comentado a peticion -->
						<!-- <span v-if="!servicio.nulo && servicio.tipo !== 'seguro' && servicio.tipo !== 'festival'">
							{{ servicio.numDias }}
							<span v-if="servicio.numDias == 1"> {{ $t('general.dia') }}</span>
							<span v-else> {{ $t('general.dias') }}</span>
						</span> -->
						{{ servicio.nombre }}
						<template v-if="!servicio.nulo && subtipo !== 'consumicion' && subtipo !== 'complemento'">
							({{ $t('general.del') }}
							{{ formatDateDDmm(servicio.fechaInicio) }}
							{{ $t('general.al') }}
							{{ formatDateDDmm(servicio.fechaFin) }})
						</template>
						<template v-if="servicio.precio && isServicioSeleccionable(servicio)">
							(<template v-if="servicio.precio > 0">+</template>{{ servicio.precio }}{{ agenciaStore.getCurrency }})
						</template>
						<template v-if="!isServicioSeleccionable(servicio)">({{ $t('general.agotado') }})</template>
					</option>
				</select>
				<div v-if="!serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio.nominal">
					<InputSelectorCantidad
						:disabled="!isServicioContratable(reserva, serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio)"
						:count="serviciosWithCantidadBySubtipoSeleccionados[subtipo].cantidad"
						:min-count="
							isServicioDescontratable(reserva, serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio)
								? 1
								: serviciosWithCantidadBySubtipoSeleccionados[subtipo].cantidadOrginial
						"
						:max-count="calculaMaxCantidadForServicio(serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio)"
						:real-max-count="
							calculaMaxRealCantidadForServicio(serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio)
						"
						@update-count="newCount => handleCantidadServicioSeleccionadoChange(subtipo, newCount)" />
				</div>
			</div>

			<div class="row">
				<div class="row text-primary mb-2 description-text">
					<template
						v-if="
							serviciosWithCantidadBySubtipoSeleccionados[subtipo] &&
							!serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio.nulo
						">
						<div class="col-8 translate">
							{{ serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio.descripcion }}
						</div>
					</template>
					<div v-if="categoriaServicios.categoria === 'seguro'" class="col text-end">
						<buttonOpenPdf
							button-text="Ver Poliza"
							:id-servicio="serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio.id"
							:id-viaje="viajeId"
							class="btn-poliza" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { formatDateDDmm } from '@/helpers/dateUtiles';
	import { firstLetterCapital } from '@/helpers/languageUtils';
	import { isServicioContratable, isServicioDescontratable } from '@/helpers/serviciosUtils';
	import { reactive, onBeforeMount, computed } from 'vue';
	import buttonOpenPdf from '../../../components/buttons/buttonOpenPdf.vue';
	import { useRoute } from 'vue-router';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import InputSelectorCantidad from '@/components/inputs/InputSelectorCantidad';
	import { toRawDeep } from '@/helpers/reactivityUtils';

	const props = defineProps({
		idxReserva: {
			type: Number,
			default: null,
		},
		categoriaServicios: {
			type: Object,
			default: null,
		},
	});

	const emits = defineEmits(['servicioSeleccionado']);

	const storeHabitacion = informacionHabitacion();
	const agenciaStore = agenciaStoreModule();
	const serviciosWithCantidadBySubtipoSeleccionados = reactive({});
	const subtipos = reactive([]);
	const serviciosCategoriaBySubtipo = reactive({});
	// todo: get this from pinia store
	const viajeId = useRoute().params.viajeId;

	const reserva = computed(() => storeHabitacion.getReservaByIdx(props.idxReserva));

	function isServicioSeleccionado(servicio) {
		return serviciosWithCantidadBySubtipoSeleccionados[servicio.subtipo].servicio.id === servicio.id;
	}

	function isServicioSeleccionable(servicio) {
		return (
			isServicioContratable(reserva.value, servicio) &&
			(isServicioSeleccionado(servicio) || servicio.nulo || calculaMaxCantidadForServicio(servicio) > 0)
		);
	}

	function calculaMaxRealCantidadForServicio(servicio) {
		let cantidadActual = 0;
		const currentServicioCategorizado = findServicioCategorizado(servicio.id);
		const servicioWithCantidadSeleccionado = serviciosWithCantidadBySubtipoSeleccionados[servicio.subtipo];
		if (
			servicio.cuposMetasId?.some(cupoId => servicioWithCantidadSeleccionado.servicio.cuposMetasId?.includes(cupoId))
		) {
			cantidadActual = servicioWithCantidadSeleccionado.cantidad;
		}
		return cantidadActual + currentServicioCategorizado.numCupos;
	}

	function calculaMaxCantidadForServicio(servicio) {
		return Math.min(calculaMaxRealCantidadForServicio(servicio), 10);
	}

	function addServiciosToReserva(isModificacion = false) {
		subtipos.forEach(subtipo => {
			storeHabitacion.addServicioToReserva(
				props.idxReserva,
				serviciosWithCantidadBySubtipoSeleccionados[subtipo].servicio,
				serviciosWithCantidadBySubtipoSeleccionados[subtipo].cantidad,
				isModificacion
			);
		});
		emits('servicioSeleccionado', Object.values(serviciosWithCantidadBySubtipoSeleccionados));
	}

	function handleCantidadServicioSeleccionadoChange(subtipo, newCantidad) {
		const servicioWithCantidadSeleccionado = serviciosWithCantidadBySubtipoSeleccionados[subtipo];
		servicioWithCantidadSeleccionado.cantidad = newCantidad;
		addServiciosToReserva(true);
		if (servicioWithCantidadSeleccionado.cantidad < servicioWithCantidadSeleccionado.oldCantidad) {
			storeHabitacion.incrementCupoServicios(servicioWithCantidadSeleccionado.servicio);
		} else {
			storeHabitacion.decrementCupoServicios(servicioWithCantidadSeleccionado.servicio);
		}
		servicioWithCantidadSeleccionado.oldCantidad = newCantidad;
	}

	function handleServicioSeleccionadoChange(event, subtipo) {
		const servicioWithCantidadSeleccionado = serviciosWithCantidadBySubtipoSeleccionados[subtipo];
		servicioWithCantidadSeleccionado.servicio = toRawDeep(findServicioCategorizado(parseInt(event.target.value)));
		servicioWithCantidadSeleccionado.servicio.justSelected = true;
		servicioWithCantidadSeleccionado.cantidad = 1;
		servicioWithCantidadSeleccionado.cantidadOrginial = 1;
		addServiciosToReserva(true);
		if (!servicioWithCantidadSeleccionado.servicio.nulo) {
			storeHabitacion.decrementCupoServicios(servicioWithCantidadSeleccionado.servicio);
		}
		if (!servicioWithCantidadSeleccionado.oldServicio.nulo) {
			storeHabitacion.incrementCupoServicios(
				servicioWithCantidadSeleccionado.oldServicio,
				servicioWithCantidadSeleccionado.oldCantidad
			);
		}
		servicioWithCantidadSeleccionado.oldServicio = servicioWithCantidadSeleccionado.servicio;
		servicioWithCantidadSeleccionado.oldCantidad = 1;
	}

	function getClassServicio() {
		if (serviciosCategoriaBySubtipo) {
			if (Object.keys(serviciosCategoriaBySubtipo).length === 1) {
				return 'col-md-12';
			} else if (Object.keys(serviciosCategoriaBySubtipo).length === 2) {
				return 'col-md-6';
			}
			return 'col-md-4';
		}
	}

	function findServicioCategorizado(servicioId) {
		return props.categoriaServicios.servicios.find(s => s.id === servicioId);
	}

	function init() {
		// All the subtipos are gathered
		props.categoriaServicios.servicios.map(({ subtipo }) => {
			if (subtipos.indexOf(subtipo) === -1) {
				subtipos.push(subtipo);
			}
		});

		// The servicios are gathered by subtipo
		subtipos.forEach(subtipo => {
			serviciosCategoriaBySubtipo[subtipo] = props.categoriaServicios.servicios.filter(
				servicio => subtipo === servicio.subtipo
			);
			const categoriaSubtipo = `${props.categoriaServicios.categoria}${firstLetterCapital(subtipo)}`;
			const serviciosSubtipo = serviciosCategoriaBySubtipo[subtipo];
			const reservaCurrentServicioWithCantidad = reserva.value.serviciosWithCantidad[categoriaSubtipo];
			const servicioPB = serviciosSubtipo.find(({ paqueteBasico }) => paqueteBasico) || null;
			// If the booking does not have any service of this category needs to be initialised
			if (reservaCurrentServicioWithCantidad === undefined) {
				const servicio = serviciosSubtipo.reduce((accum, s) => {
					if ((s.paqueteBasico && s.numCupos > 0) || (accum == null && s.nulo)) {
						return s;
					} else {
						return accum;
					}
				}, null);
				serviciosWithCantidadBySubtipoSeleccionados[subtipo] = {
					servicio: servicio,
					oldServicio: servicio,
					cantidad: 1,
					oldCantidad: 1,
					cantidadOrginial: 1,
					categoriaPaqueteBasico: servicioPB !== null,
					servicioPB: servicioPB,
				};
			} else {
				// Otherwise the current value is load
				const currentServicioCategorizado = findServicioCategorizado(reservaCurrentServicioWithCantidad.servicio.id);
				serviciosWithCantidadBySubtipoSeleccionados[subtipo] = {
					servicio: currentServicioCategorizado,
					oldServicio: currentServicioCategorizado,
					cantidad: reservaCurrentServicioWithCantidad.cantidad,
					oldCantidad: reservaCurrentServicioWithCantidad.cantidad,
					cantidadOrginial: reservaCurrentServicioWithCantidad.cantidad,
					categoriaPaqueteBasico: servicioPB !== null,
					servicioPB: servicioPB,
				};
			}
		});
		addServiciosToReserva();
	}

	onBeforeMount(() => {
		init();
	});
</script>

<style lang="scss" scoped>
	.title-card {
		background-color: #f9f7f8;
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: var(--bs-border-radius-lg);

		.text {
			display: flex;
			align-items: center;

			i {
				margin-right: 0.5rem;
			}
		}
	}

	.dot {
		height: 25px;
		width: 25px;
		background-color: var(--bs-secondary);
		border-radius: 50%;
		display: inline-block;
	}

	.dot-disabled {
		height: 25px;
		width: 25px;
		background-color: #727272;
		border-radius: 50%;
		display: inline-block;
	}

	.selector-servicio {
		background-color: rgba(var(--bs-primary-rgb), 0.02);
		box-shadow: 0 0 3px 0 rgba(var(--bs-primary-rgb), 0.35) inset;
	}

	.btn-poliza {
		@media only screen and (max-width: 768px) {
			font-size: 10px;
		}
	}

	.description-text {
		font-size: 12px;
		font-weight: 400;
		margin-top: 0.4rem;

		@media only screen and (max-width: 768px) {
			font-size: 10px;
		}
	}

	.selector-servicio.rounded-end {
		border-top-right-radius: var(--bs-border-radius) !important;
		border-bottom-right-radius: var(--bs-border-radius) !important;
	}

	.messageIfIsNominal {
		background-color: rgba(var(--bs-secondary-rgb), 0.35);
		font-size: 0.6rem;
	}
</style>
