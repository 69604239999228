<template>
	<div class="carousel-wrapper bg-light rounded shadow">
		<Carousel ref="carousel" :items-to-show="1" :items-to-scroll="1" wrap-around :autoplay="3000">
			<Slide v-for="(itinerario, index) in itinerarios" :key="index">
				<div class="carousel-slide d-flex flex-lg-row flex-md-row flex-sm-column align-items-start w-100 h-100">
					<div class="flex-grow-1 p-3 text-start carousel-text-container me-3">
						<div class="title-with-arrows align-items-center d-flex">
							<div
								class="carousel-arrow mb-3 d-flex justify-content-center align-items-center pointer carousel-prev fs-1"
								@click="prevSlide">
								&lsaquo;
							</div>
							<div class="title-date-container d-flex flex-column align-items-start me-3">
								<h3 class="fw-bold fs-4 carousel-text align-items-center d-flex ms-2">
									<span class="day">
										{{ $t('general.diaMayus') }} {{ index + 1 }}<span class="fs-6 mt-2">/{{ itinerarios.length }}</span>
									</span>
									<span class="date fs-6 ms-3">{{ formatDate(itinerario.fecha) }}</span>
								</h3>
								<p class="carousel-text d-flex align-items-center fw-bold fs-6 text-primary ms-2 location-text">
									<LocationIcon class="me-2 overflow-visible" /> {{ localizacion }}
								</p>
							</div>
							<div class="carousel-arrow pointer mb-3 carousel-next fs-1" @click="nextSlide">&rsaquo;</div>
						</div>
						<div class="carousel-description-container mt-1 ms-2 small">
							<div v-sanitize-html="itinerario.descripcion" class="carousel-description translate"></div>
						</div>
					</div>
					<img :src="itinerario.imagenItinerario" class="carousel-image" alt="Itinerario Imagen" />
				</div>
			</Slide>
		</Carousel>
	</div>
</template>

<script setup>
	import { computed, ref } from 'vue';
	import { Carousel, Slide } from 'vue3-carousel';
	import 'vue3-carousel/dist/carousel.css';
	import LocationIcon from '@/components/Icons/LocationIcon.vue';
	import { useI18n } from 'vue-i18n';

	defineProps({
		itinerarios: {
			type: Array,
			required: true,
		},
		localizacion: {
			type: String,
			required: true,
		},
	});

	const { locale } = useI18n();
	const carousel = ref(null);

	const formatDate = computed(() => {
		return dateString => {
			const date = new Date(dateString);
			const options = { day: '2-digit', month: 'long' };
			let formattedDate = new Intl.DateTimeFormat(locale.value, options).format(date);
			formattedDate = formattedDate.replace(/ de /gi, ' ').toUpperCase();
			return formattedDate;
		};
	});

	function prevSlide() {
		carousel.value.prev();
	}

	function nextSlide() {
		carousel.value.next();
	}
</script>
<style scoped>
	.date {
		color: var(--bs-secondary);
	}

	.carousel-text {
		color: var(--bs-primary);
	}

	.title-date-container {
		max-width: 75%;
	}

	.carousel-wrapper {
		max-height: 450px;
	}

	.carousel-text-container {
		max-height: 450px;
		width: 50%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}

	.carousel-description-container {
		max-height: 300px;
		overflow: hidden;
	}

	.carousel-description {
		color: var(--bs-primary);
		white-space: pre-wrap;
		word-break: break-word;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 14;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.carousel-image {
		width: 50%;
		height: 100%;
		max-height: 450px;
		object-fit: cover;
		border-radius: 0 var(--bs-border-radius-lg) var(--bs-border-radius-lg) 0;
	}

	.carousel-arrow {
		color: var(--bs-primary);
		font-weight: 500;
		width: 1.875rem;
		height: 1.875rem;
		display: flex;
		align-items: center;
	}

	.carousel-prev {
		margin-right: 0.625rem;
	}

	.carousel-next {
		margin-left: 0.9375rem;
	}

	.location-text {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;
	}

	@media (max-width: 768px) {
		.carousel-slide {
			flex-direction: column;
		}

		.title-with-arrows {
			position: relative;
		}

		.carousel-text-container {
			width: 100%;
		}

		.carousel-image {
			width: 100%;
			max-height: 200px;
			object-fit: cover;
			border-radius: 0 0 var(--bs-border-radius-lg) var(--bs-border-radius-lg);
			order: 1;
			margin-top: auto;
		}

		.carousel-description-container {
			max-height: 200px;
		}

		.carousel-description {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 7;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.carousel-prev {
			right: 2.1875rem;
		}

		.carousel-next {
			right: 0.3125rem;
		}
	}
</style>
