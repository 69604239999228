<template>
	<teleport to="body">
		<modal-base
			:show="modalActive"
			:title="$t('reserva.comunidad.crear')"
			:align-footer="'center'"
			@on-close="emits('close-modal')">
			<template #body>
				<div class="input-container px-1">
					<input
						v-model="nombreComunidad"
						type="text"
						:placeholder="$t('reserva.comunidad.placeholder')"
						class="form-control"
						@keyup.enter="handleCrear" />
				</div>
			</template>
			<template #footer>
				<div class="d-flex flex-column align-items-center">
					<div class="button-group mb-2">
						<button class="text-primary btn-link" @click="emits('close-modal')">
							{{ $t('botones.cancelar') }}
						</button>
						<button class="btn btn-primary btn-crear" :disabled="!nombreComunidad.trim()" @click="handleCrear">
							{{ $t('general.crear') }}
						</button>
					</div>
					<div v-if="showError" class="error-container mt-2">
						<span class="error-message">{{ errorMessage }}</span>
					</div>
				</div>
			</template>
		</modal-base>
	</teleport>
</template>

<script setup>
	import { ref } from 'vue';
	import ModalBase from '@/components/modales/ModalBase';

	defineProps({
		modalActive: {
			type: Boolean,
			required: true,
		},
	});

	const emits = defineEmits(['close-modal', 'crear-comunidad']);

	const nombreComunidad = ref('');
	const errorMessage = ref('');
	const showError = ref(false);

	function handleCrear() {
		if (nombreComunidad.value.trim()) {
			emits('crear-comunidad', nombreComunidad.value);
			nombreComunidad.value = '';
			errorMessage.value = '';
			showError.value = false;
			emits('close-modal');
		}
	}
</script>

<style scoped lang="scss">
	.input-container {
		padding: 1rem 0;
	}

	.form-control {
		width: 100%;
		padding: 0.4rem 1rem;
		font-size: 14px;
		border-radius: var(--bs-border-radius-lg);
		color: var(--bs-primary);
		text-align: left;
	}

	.btn-link {
		text-align: center;
		font-size: 14px;
		font-weight: 500;
		padding: 10px 20px;
		color: var(--bs-primary);
		border: 1px solid var(--bs-primary);
		border-radius: 50px;
		background: none;
		cursor: pointer;
		text-decoration: none;

		&:hover {
			background-color: rgba(var(--bs-primary-rgb), 0.05);
		}
	}

	.btn-crear {
		box-shadow: 0px 1px 2px 0px rgba(var(--bs-primary-rgb), 0.35);

		font-size: 14px;
		font-weight: 500;
		padding: 10px 20px;

		&:disabled {
			opacity: 0.5;
			background-color: var(--bs-secondary);
			color: var(--bs-primary);
		}
	}

	.button-group {
		display: flex;
		gap: 10px;
		justify-content: center;
		align-items: center;
	}

	.error-message {
		color: #e54a5c;
		font-size: 14px;
		font-weight: bold;
		text-align: center;
	}

	:deep(.modal-container) {
		@media only screen and (min-width: 768px) {
			min-width: unset;
			max-width: unset;
			width: 800px;
		}

		@media only screen and (max-width: 768px) {
			padding-bottom: 10px;
		}
	}
</style>
