<template>
	<div class="tabs-container">
		<div class="tabs-wrapper">
			<i v-if="showArrows" class="fa-solid fa-chevron-left flechas pointer" @click="handleScrollLeft"> </i>

			<div
				ref="scrollContainer"
				class="tabs-scroll-container"
				@mousedown="startDragging"
				@mousemove="onDrag"
				@mouseup="stopDragging"
				@mouseleave="stopDragging"
				@touchstart="startDragging"
				@touchmove="onDrag"
				@touchend="stopDragging">
				<div class="tabs-content">
					<div v-for="tab in tabs" :key="tab.id" class="tab-item">
						<button class="tab-button" :class="{ active: activeTab === tab.id }" @click="handleTabClick(tab)">
							{{ tab.label }}
						</button>
						<div v-if="activeTab === tab.id" class="tab-indicator"></div>
					</div>
				</div>
			</div>
			<i v-if="showArrows" class="fa-solid fa-chevron-right flechas pointer" @click="handleScrollRight"> </i>
		</div>
	</div>
</template>

<script setup>
	import { useDraggableScroll, useScrollableContainer } from '@/helpers/gestureUtils';

	import { ref, onMounted, onBeforeUnmount } from 'vue';

	const props = defineProps({
		tabs: {
			type: Array,
			required: true,
		},
		initialTab: {
			type: String,
			default: null,
		},
		mode: {
			type: String,

			default: 'scroll',
			validator: value => ['switch', 'scroll'].includes(value),
		},
	});

	const emit = defineEmits(['tab-changed']);

	const { scrollContainer, showArrows, handleScrollLeft, handleScrollRight, setupScrollableContainer } =
		useScrollableContainer();
	const { startDragging, onDrag, stopDragging } = useDraggableScroll();

	const activeTab = ref(props.initialTab || props.tabs[0]?.id);
	const observer = ref(null);

	function handleTabClick(tab) {
		activeTab.value = tab.id;

		if (props.mode === 'scroll') {
			const element = document.getElementById(`section-${tab.id}`);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}

		emit('tab-changed', tab.id);
	}

	onMounted(() => {
		if (props.mode === 'scroll') {
			const observerOptions = {
				root: null,
				rootMargin: '0px',
				threshold: 0.5,
			};

			observer.value = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						const sectionId = entry.target.id.replace('section-', '');
						activeTab.value = sectionId;
					}
				});
			}, observerOptions);

			props.tabs.forEach(tab => {
				const element = document.getElementById(`section-${tab.id}`);
				if (element) observer.value.observe(element);
			});
		}

		setupScrollableContainer({
			source: props.tabs,
			enabled: true,
			debounceTime: 100,
		});
	});

	onBeforeUnmount(() => {
		if (observer.value) {
			observer.value.disconnect();
		}
	});
</script>

<style lang="scss" scoped>
	.tabs-container {
		width: 100%;
		position: sticky;
		top: 0;
		background: white;
		z-index: 10;
	}

	.tabs-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		border-bottom: 1px solid var(--bs-gray-300);
	}

	.tabs-scroll-container {
		width: 100%;
		overflow-x: auto;
		scrollbar-width: none;
		-ms-overflow-style: none;
		&::-webkit-scrollbar {
			display: none;
		}
	}

	.tabs-content {
		display: flex;
		position: relative;
		width: 100%;
	}

	.tab-item {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		&.active {
			color: var(--bs-primary);
			font-weight: 600;
			background-color: #f9f7f8;
			height: 100%;
		}
		@media screen and (min-width: 768px) {
			flex: 1;
		}

		@media screen and (max-width: 768px) {
			min-width: max-content;
		}
	}

	.tab-button {
		padding: 0.5rem 0.7rem;
		background: none;
		border: none;
		color: var(--bs-primary);
		font-size: 0.9rem;
		font-weight: 500;
		transition: all 0.3s ease;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		max-height: 4rem;
		text-align: center;
		min-height: 3rem;
		width: 100%;

		&:hover {
			color: var(--bs-primary);
		}

		&.active {
			color: var(--bs-primary);
			font-weight: 600;
			background-color: #f9f7f8;
			height: 100%;
		}
	}

	.tab-indicator {
		position: absolute;
		bottom: -1px;
		height: 2px;
		background-color: var(--bs-primary);
		width: 100%;
	}

	.flechas {
		position: absolute;
		color: var(--bs-primary);
		font-size: 15px;
		cursor: pointer;
		z-index: 1;
		height: 100%;
		display: flex;
		align-items: center;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
		display: none;

		@media screen and (max-width: 767px) {
			display: flex;
		}

		&:first-child {
			left: 0;
		}

		&:last-child {
			right: 0;
			background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
		}
	}
</style>
