export function comprimirImagen(imagenComoArchivo, porcentajeCalidad) {
	return new Promise(function (resolve, reject) {
		const $canvas = document.createElement('canvas');
		const imagen = new Image();
		imagen.onload = function () {
			$canvas.width = imagen.width;
			$canvas.height = imagen.height;
			$canvas.getContext('2d').drawImage(imagen, 0, 0);
			$canvas.toBlob(
				function (blob) {
					if (blob === null) {
						return reject(blob);
					} else {
						resolve(blob);
					}
				},
				'image/jpeg',
				porcentajeCalidad / 100
			);
		};
		imagen.src = URL.createObjectURL(imagenComoArchivo);
	});
}
