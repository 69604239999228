import { ref, onUnmounted, nextTick, watch } from 'vue';

export function useDraggableScroll() {
	const isDragging = ref(false);
	const startX = ref(0);
	const scrollLeft = ref(0);

	function startDragging(e, isInlineMode = true) {
		if (!isInlineMode) return;

		isDragging.value = true;
		const container = e.currentTarget;
		const pageX = e.type.includes('mouse') ? e.pageX : e.touches[0].pageX;
		startX.value = pageX - container.offsetLeft;
		scrollLeft.value = container.scrollLeft;
	}

	function onDrag(e, isInlineMode = true) {
		if (!isDragging.value || !isInlineMode) return;

		e.preventDefault();
		const container = e.currentTarget;
		const pageX = e.type.includes('mouse') ? e.pageX : e.touches[0].pageX;
		const x = pageX - container.offsetLeft;
		const walk = (x - startX.value) * 2;
		container.scrollLeft = scrollLeft.value - walk;
	}

	function stopDragging(isInlineMode = true) {
		if (!isInlineMode) return;
		isDragging.value = false;
	}

	return {
		isDragging,
		startDragging,
		onDrag,
		stopDragging,
	};
}

export function useScrollableContainer() {
	const scrollContainer = ref(null);
	const showArrows = ref(false);

	const handleScrollLeft = () => {
		scrollContainer.value?.scrollBy({ left: -200, behavior: 'smooth' });
	};

	const handleScrollRight = () => {
		scrollContainer.value?.scrollBy({ left: 200, behavior: 'smooth' });
	};

	const checkScrollable = () => {
		if (scrollContainer.value) {
			const { scrollWidth, clientWidth } = scrollContainer.value;
			showArrows.value = scrollWidth > clientWidth;
		}
	};

	const setupScrollableContainer = ({ source, enabled, isInlineMode = true }) => {
		if (!enabled || !isInlineMode) {
			showArrows.value = false;
			return;
		}

		watch(
			source,
			() => {
				nextTick(() => {
					checkScrollable();
				});
			},
			{ immediate: true }
		);

		const resizeObserver = new ResizeObserver(() => {
			checkScrollable();
		});

		nextTick(() => {
			checkScrollable();
			if (scrollContainer.value) {
				resizeObserver.observe(scrollContainer.value);
			}
		});

		onUnmounted(() => {
			resizeObserver.disconnect();
		});
	};

	return {
		scrollContainer,
		showArrows,
		handleScrollLeft,
		handleScrollRight,
		setupScrollableContainer,
	};
}
