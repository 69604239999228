<template>
	<div class="col my-2">
		<div class="title-card d-flex p-3 justify-content-between align-items-center rounded-2">
			<div class="d-flex align-items-center">
				<i :class="categoriaServiciosMetaData.icono" class="me-2 text-primary" />
				<span class="text-primary">{{ categoriaServiciosMetaData.titulo }}</span>
				<InfoIcon
					v-if="hasServiciosNominales"
					class="custom-svgInfo"
					size="12px"
					:message-info="t('servicios.tooltipMessageIsNominal')" />
			</div>
			<div class="d-flex">
				<span v-if="precioTotal.value !== 0" class="text-primary px-2 extrasServicePrice">
					<span v-if="precioTotal > 0">+</span>{{ precioTotal }}{{ agenciaStore.getCurrency }}
				</span>
			</div>
		</div>
		<div class="body-card">
			<div class="row d-flex justify-content-center mt-3">
				<template v-if="categoriaServicios.arquetipo === 'individual'">
					<ReservaServiciosIndividualSelector
						:idx-reserva="idxReserva"
						:categoria-servicios="categoriaServicios"
						@servicio-seleccionado="handlerServicioSeleccionado" />
				</template>
				<template v-else-if="categoriaServicios.arquetipo === 'subfiltro'">
					<ReservaServiciosSubfiltrosSelector
						:idx-reserva="idxReserva"
						:categoria-servicios="categoriaServicios"
						@servicio-seleccionado="handlerServicioSeleccionado" />
				</template>
				<template v-else-if="categoriaServicios.arquetipo === 'subtipo'">
					<ReservaServiciosSubtipoSelector
						:categoria-servicios="categoriaServicios"
						:idx-reserva="idxReserva"
						@servicio-seleccionado="handlerServicioSeleccionado" />
				</template>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed } from 'vue';
	import ReservaServiciosSubfiltrosSelector from '@/modules/viajesGrupos/components/ReservaServiciosSubfiltrosSelector.vue';
	import ReservaServiciosIndividualSelector from '@/modules/viajesGrupos/components/ReservaServiciosIndividualSelector.vue';
	import ReservaServiciosSubtipoSelector from '@/modules/viajesGrupos/components/ReservaServiciosSubtipoSelector.vue';
	import { getCategoriasServiciosMetaData } from '@/helpers/serviciosUtils';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import currency from 'currency.js';
	import { calculaPrecioForServicioWithCantidad } from '@/helpers/viajesGrupos';
	import InfoIcon from '@/components/Icons/InfoIcon.vue';
	import { useI18n } from 'vue-i18n';

	const props = defineProps({
		categoriaServicios: {
			type: Object, // VGServiciosCategorizadosDTO
			default: null,
		},
		idxReserva: {
			type: Number,
			default: null,
		},
	});

	const { t } = useI18n();
	const agenciaStore = agenciaStoreModule();
	const servicioWithCantidadSeleccionado = ref(null);
	const categoriaServiciosMetaData = getCategoriasServiciosMetaData().find(
		({ selector }) => selector === props.categoriaServicios.categoria
	);

	const precioTotal = computed(() => {
		let serviciosWithCantidad = servicioWithCantidadSeleccionado.value || [];
		if (!Array.isArray(serviciosWithCantidad)) {
			serviciosWithCantidad = [serviciosWithCantidad];
		}
		return serviciosWithCantidad.reduce(
			(accum, swc) => accum.add(calculaPrecioForServicioWithCantidad(swc, false)),
			currency(0)
		);
	});

	const hasServiciosNominales = computed(() => {
		return props.categoriaServicios.servicios.some(servicio => servicio.nominal && !servicio.nulo);
	});

	function handlerServicioSeleccionado(data) {
		servicioWithCantidadSeleccionado.value = data;
	}
</script>

<style lang="css">
	.title-card {
		background-color: rgba(249, 247, 248, 1);

		border-radius: var(--bs-border-radius-lg);
	}

	.extrasServicePrice {
		font-size: 1.1rem;
		font-weight: 500;
	}

	.custom-svgInfo {
		margin-left: 0.3rem;
	}

	.custom-svgInfo path {
		fill: #636c87;
	}
</style>
