<template>
	<div
		v-for="reserva in reservasPagoPendiente"
		:key="reserva.id"
		class="w-100 text-white fw-normal justify-content-between mb-3 mx-0">
		<TarjetaProximaReservaAcciones
			:from-mis-reservas="true"
			:action="'Pay'"
			:reserva="reserva"
			:grupo="grupo"
			:unique="reservasPagoPendiente.length === 1"
			:reset="reset && regresaComprar" />
	</div>

	<div class="col-12 my-2">
		<div class="tabla-pagos gx-0">
			<table>
				<thead>
					<tr>
						<th>{{ $t('reserva.concepto') }}</th>
						<th>{{ $t('general.fecha') }}</th>
						<th class="text-end">{{ $t('general.pagado') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(pago, concepto) in pagosAgrupados" :key="concepto">
						<td>
							<div class="py-2">
								{{ getPagosConcepto(concepto) }}
							</div>

							<template v-for="nombre in pago.nombres" :key="nombre">
								<div class="text-nombre">
									{{ nombre }}
								</div>
							</template>
						</td>
						<td>{{ pago.fecha }}</td>
						<td class="text-secondary text-number text-end">{{ pago.total.toFixed(2) }} {{agenciaStore.getCurrency}}</td>
					</tr>

					<tr>
						<td><span class="text-pagado"> </span> {{ $t('subtitulo.total') }} {{ $t('general.pagado') }}</td>
						<td></td>
						<td class="text-secondary text-number text-end">
							{{ grupo.viaje.hasPagoDiferido ? totalPendiente.toFixed(2) : sumaTotalPagos(grupo.reservas) }}
							{{agenciaStore.getCurrency}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="my-3">
		<span
			v-show="!mostrarFormDescuento"
			class="text-decoration-underline text-codigo"
			@click="mostrarFormDescuento = true">
			{{ $t('codigoDescuento.tienesCodDescuento') }}
		</span>

		<div v-show="mostrarFormDescuento">
			<FormCodigoDescuento
				:no-title="true"
				:viaje-id="String(grupo.viaje.id)"
				:reservas-ids="reservasPagoPendiente.map(reserva => reserva.id)"
				@descuento-aplicado="saveCodigoDescuento" />
		</div>
	</div>

	<CardReservaPagos :is-pagos-sucesivos="true" @pago-unico="handlePagoUnicoChange" />

	<template v-if="loadingPay">
		<SpinnerVue />
	</template>

	<div class="modal-footer border-0 my-2 text-center" style="justify-content: center">
		<button type="button" class="btn btn-primary" @click="realizarPagosPendientes()">{{ $t('botones.pagar') }}</button>
	</div>

	<div v-if="infoPagoRedsys">
		<form name="redsysPagoForm" :action="infoPagoRedsys.url" method="POST">
			<input type="hidden" name="Ds_SignatureVersion" :value="infoPagoRedsys.signatureVersion" />
			<input type="hidden" name="Ds_MerchantParameters" :value="infoPagoRedsys.params" />
			<input type="hidden" name="Ds_Signature" :value="infoPagoRedsys.signature" />
		</form>
	</div>
</template>

<script setup>
	import {ref, computed, watch, nextTick, onBeforeMount} from 'vue';
	import TarjetaProximaReservaAcciones from '../tarjetas/TarjetaProximaReservaAcciones.vue';
	import PasarelaPagoApiService from '@/services/api/skiandnight/PasarelaPagoApiService';
	import SpinnerVue from '../SpinnerLoading.vue';
	import { alertAutoCloseWarningWithoutCallback } from '@/services/utils/AlertUtils';
	import { useRouter } from 'vue-router';
	import FormCodigoDescuento from '@/modules/viajesGrupos/components/FormCodigoDescuento.vue';
	import CardReservaPagos from '@/components/card/Reserva/CardReservaPagos.vue';
	import { ESTADOS_RESERVA } from '@/constants/reservaConstants';
	import { useI18n } from 'vue-i18n';
	import { GruposViajesThankYou } from '@/router/RouterNames';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import { toRawDeep } from '@/helpers/reactivityUtils';

	const props = defineProps({
		grupo: {
			type: Object,
			required: true,
		},
		reset: {
			type: Boolean,
		},
	});

	const storeHabitacion = informacionHabitacion();
	const agenciaStore = agenciaStoreModule();

	const { t } = useI18n();
	const term = ref(false);
	const loadingPay = ref(false);
	const router = useRouter();
	const errorTerm = ref(false);
	const errorReserva = ref(null);
	const valorReset = computed(() => props.reset);
	const regresaComprar = ref(false);
	const dataCodigo = ref(null);
	const pagoUnico = ref(true);
	const infoPagoRedsys = ref(null);

	const mostrarFormDescuento = ref(false);

	const reservasPagoPendiente = computed(() =>
		props.grupo.reservas.filter(reserva =>
			reserva.precioTotal - reserva.totalPagado > 0 && reserva.estado !== ESTADOS_RESERVA.CANCELADA
		)
	);

	const totalPendiente = computed(() => {
		let totalPagadoAux = 0;
		let precioTotalAux = 0;
		props.grupo.reservas
			.filter(reserva => reserva.estado !== ESTADOS_RESERVA.CANCELADA)
			.forEach(({ totalPagado, precioTotal }) => {
				totalPagadoAux += totalPagado;
				precioTotalAux += precioTotal;
				if (totalPagado === 0) {
					if (props.grupo.viaje.hasPagoDiferido) {
						precioTotalAux = 0;
						totalPagadoAux = precioTotal;
					}
				}
			});

		return props.grupo.viaje.hasPagoDiferido ? totalPagadoAux : precioTotalAux - totalPagadoAux;
	});

	const pagosAgrupados = computed(() => {
		const agrupados = {};
		props.grupo.reservas.forEach(reserva => {
			reserva.pagos.forEach(pago => {
				if (!agrupados[pago.concepto]) {
					agrupados[pago.concepto] = {
						total: 0,
						nombres: [],
						fecha: pago.fecha,
					};
				}
				agrupados[pago.concepto].total += pago.cantidad;
				if (!agrupados[pago.concepto].nombres.includes(reserva.nombreUsuario)) {
					agrupados[pago.concepto].nombres.push(reserva.nombreUsuario);
				}
			});
		});
		return agrupados;
	});

	function getPagosConcepto(concepto) {
		return concepto.includes('.') ? t(`reserva.pagos.${concepto}`) : concepto;
	}

	function sumaTotalPagos(reservas) {
		let sum = 0;
		reservas.map(({ pagos }) => {
			pagos.map(({ cantidad }) => {
				sum += cantidad;
			});
		});
		return sum.toFixed(2);
	}


	function saveCodigoDescuento(data) {
		dataCodigo.value = data;
	}

	function handlePagoUnicoChange(pagoUnic) {
		pagoUnico.value = pagoUnic;
	}

	async function realizarPagosPendientes() {
		try {
			errorTerm.value = false;
			errorReserva.value = null;
			loadingPay.value = true;
			const reservasIds = props.grupo.reservas.filter(r => r.estado !== ESTADOS_RESERVA.CANCELADA).map(r => r.id);
			const datosRedireccionDTO = await PasarelaPagoApiService.solicitaCobroSucesivo(reservasIds, false, dataCodigo.value?.concepto, pagoUnico.value)
			term.value = false;
			regresaComprar.value = !regresaComprar.value;
			if (!datosRedireccionDTO.url) {
				router.push({
					name: GruposViajesThankYou,
					params: {
						grupoId: datosRedireccionDTO.grupoId
					},
					query: {
						msg: datosRedireccionDTO.msgThankyou,
					},
				});
			} else {
				if (datosRedireccionDTO.redsysInfo) {
					infoPagoRedsys.value = datosRedireccionDTO.redsysInfo;
					infoPagoRedsys.value.url = datosRedireccionDTO.url;
					await nextTick()
					document.redsysPagoForm.submit();
				} else {
					window.location.replace(datosRedireccionDTO.url);
				}
			}
		} catch (err) {
			if (err.response.status === 409) {
				alertAutoCloseWarningWithoutCallback(4000, t('errors.pagoTransferencia'));
			}
		}
	}

	async function init() {
		await storeHabitacion.loadInformacionViaje(props.grupo.viaje.id, props.grupo.viaje.codigo ?? null);
		const grupoRaw = toRawDeep(props.grupo);
		storeHabitacion.setInformacionAlojamientoFromRGrupoDTO(grupoRaw);
		storeHabitacion.clearReservas();
		grupoRaw.reservas.map(reserva => {
			reserva.usuario = {
				nombre: reserva.nombre,
				apellidos: reserva.apellidos,
				apellido: reserva.apellidos,
				isMenor: reserva.isMenor,
			};
			storeHabitacion.addReserva(reserva);
		});
	}

	watch(valorReset, () => {
		term.value = false;
	});

	onBeforeMount(async () => await init());

</script>

<style scoped>
	@media screen and (max-width: 1000px) {
		.contenedor {
			margin: 0 12px;
		}

		.titulo {
			font-size: 16px !important;
		}

		.tabla-pagos {
			thead {
				font-size: 12px !important;
			}

			font-size: 11px !important;
		}
		.text-number {
			font-size: 11px !important;
		}

		.btn-primary {
			font-size: 13px !important;
		}
	}

	.titulo {
		font-size: 20px;
		font-weight: 500;
	}

	.tabla-pagos {
		background-color: #f9f7f8;
		border-radius: var(--bs-border-radius-xl);
		font-size: 14px;
		padding: 0.5rem 1.25rem 1.25rem 1.25rem;

		font-size: 15px;
		font-weight: 400;

		table {
			width: 100%;
		}

		thead {
			font-size: 16px;
			font-weight: 500;
		}

		div {
			padding-bottom: 1rem;
			padding-right: 1rem;
		}

		th {
			border-bottom: 0.5px solid var(--bs-primary);
			font-size: 16px;
			font-weight: 500;
		}

		td {
			padding: 0.6rem 0.4rem;
			vertical-align: top;

			.text-nombre {
				font-size: 12px;
				font-weight: 400;
				padding: 0 0 0 10px;
			}

			.text-pagado {
				font-size: 15px;
				font-weight: 500;
			}
		}

		th {
			padding: 0.75rem;
			vertical-align: top;
		}
	}

	.row {
		padding-bottom: 0;
	}

	.btn-primary {
		text-align: center;
		font-size: 14px;
		font-weight: 500;
	}

	.text-number {
		font-size: 15px;
		font-weight: 700;
	}

	.text-codigo {
		color: var(--bs-primary);
		font-weight: 600;
		cursor: pointer;
	}
</style>
