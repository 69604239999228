export const Home = 'home';
export const InformacionAlojamiento = 'InformacionAlojamiento';
export const ReservaConfirmadaComponent = 'ReservaConfirmadaComponent';
export const GruposReservaResumen = 'GruposReservaResumen';
export const GruposReservaAlojamientos = 'GruposReservaAlojamientos';
export const GruposReservaFormulario = 'GruposReservaFormulario';
export const GruposReservaFormularioUnirse = 'GruposReservaFormularioUnirse';
export const GruposReservaFormularioUnirseSinAlojamiento = 'GruposReservaFormularioUnirseSinAlojamiento';
export const GruposInformacionViaje = 'GruposInformacionViaje';
export const Buscador = 'Buscador';
export const BuscadorAlojamiento = 'BuscadorAlojamiento';
export const NotFound = 'NotFound';
export const MisReservas = 'MisReservas';
export const ApresSkisDetalle = 'ApresSkisDetalle';
export const ApresSkisCatalogo = 'ApresSkisCatalogo';
export const MisClientes = 'MisClientes';
export const FestivalesReservarEntrada = 'FestivalesReservarEntrada';
export const FestivalesCatalogo = 'FestivalesCatalogo';
export const BuscadorServicios = 'BuscadorServicios';
export const FestivalesDetalle = 'FestivalesDetalle';
export const FestivalesServicios = 'FestivalesServicio';
export const FestivalesResumen = 'FestivalesResumen';
export const FestivalesThanks = 'FestivalesThanks';
export const UsuarioDarBajaPublicidad = 'UsuarioDarBajaPublicidad';
export const BuscadorResumen = 'BuscadorResumen';
export const Recomendador = 'DatosPersonales';
export const PrespuestoForm = 'PrespuestoForm';
export const BajaEmailPublicitario = 'BajaEmailPublicitario';
export const ValidacionQrFestivales = 'ValidarQrFestivales';
export const ValidacionQrServicios = 'ValidarQrServicios';
export const CheckIn = 'CheckIn';
export const Landing = 'landing';
export const ViajesGrupoCatalogo = 'GruposCatalogo';
export const ViajesGrupoModificarReservas = 'ModificarReservas';
export const GruposViajesThankYou = 'ThankYouPage';
export const GruposViajesErrorPago = 'ErrorPayPage';
export const GruposViajesThankYouRedireccion = 'ThankYouPageRedireccion';
export const UsuarioProximasReservas = 'UsuarioProximasReservas';
export const UsuarioProximasReservasDetalle = 'UsuarioProximasReservasDetalle';
export const UsuarioProximaReservasModificar = 'UsuarioProximaReservasModificar';
export const UsuarioProximaReservasConfirmacion = 'UsuarioProximaReservasConfirmacion';
export const UsuarioRooming = 'UsuarioRooming';
export const FAQS = 'FAQS';
export const TrabajaConNosotros = 'TrabajaConNosotros';
export const BlogLanding = 'BlogLanding';
export const UnirseAComunidad = 'UnirseAComunidad';
