<template>
	<div class="container-view">
		<div class="col-12 mb-3 fw-bold atras">
			<div @click="$router.go(-1)">&lt;{{ $t('home.form.backButton') }}</div>
		</div>
		<ul class="nav nav-tabs">
			<li class="nav-item fs-5 text-primary fw-bold mb-4" @click="activeView = 'checkin'">
				<a class="nav-link" :class="activeView === 'checkin' ? 'active' : ''" aria-current="page">
					{{ $t('general.checkInOnline') }}
				</a>
			</li>
			<li class="nav-item fs-5 text-primary fw-bold mb-4" @click="activeView = 'informe'">
				<a
					class="nav-link"
					aria-current="page"
					:class="
						activeView === 'informe'
							? 'active'
							: '' || formulariosInscripcionBack.informeTotalesHash == null
							? 'd-none'
							: ''
					">
					{{ $t('reserva.informesTotales') }}
				</a>
			</li>
			<li class="nav-item fs-5 text-primary fw-bold mb-4" @click="activeView = 'cambio'">
				<a
					class="nav-link"
					aria-current="page"
					:class="
						activeView === 'cambio'
							? 'active'
							: '' || formulariosInscripcionBack.grupoChanged == null
							? 'd-none'
							: formulariosInscripcionBack.grupoChanged
							? 'text-danger'
							: ''
					">
					{{ $t('reserva.cambioHabitacion') }}
				</a>
			</li>
		</ul>

		<Spinner v-if="loading || showSpinner" />

		<template v-if="error">
			<div>{{ $t('errors.cargaInformacion') }}</div>
		</template>

		<div v-if="!error && !loading" :class="showSpinner ? 'd-none' : 'd-block'">
			<template v-if="activeView === 'checkin'">
				<div v-for="formulario in formulariosInscripcionBack.formularios" :key="formulario.id" class="mb-4">
					<FormularioInscripcionReserva
						:formulario="formulario"
						force-show
						@update-formulario="updateFormularioHandler" />
				</div>
				<div v-if="anyFormularioShowed" class="row justify-content-center my-5 px-2">
					<button type="button" class="boton boton-checkin rounded-4 text-primary" @click="doCheckIn">
						{{ $t('home.form.realizarCheckin') }}
					</button>
				</div>
			</template>

			<template v-if="activeView === 'cambio'">
				<div>
					<p v-if="formulariosInscripcionBack.grupoChanged != null" class="mt-4">
						<span class="fw-bold">{{ $t('reserva.cambioHabitacion') }}:</span>
						<span v-if="formulariosInscripcionBack.grupoChanged">{{ $t('general.si') }}</span>
						<span v-else>{{ $t('general.no') }}</span>
					</p>
					<p v-for="f in formulariosInscripcionBack.formularios" :key="f.id">
						{{ f.nombre }} :
						{{ getIdsServiciosNoNulos(r.serviciosWithCantidad) }}
					</p>

					<p class="fs-5 fw-bold">
						{{ $t('reserva.descargar') }}
					</p>

					<p v-for="servicio in getListaServicios(reservas)" :key="servicio.id">
						({{ servicio.id }} )
						<span v-if="servicio.tipo !== 'festival'">
							{{ servicio.numDias }} <span v-if="servicio.numDias === 1"> {{ $t('general.dia') }}</span>
							<span v-else> {{ $t('general.dias') }}</span>
						</span>
						{{ servicio.nombreCompleto }} {{ $t('general.del') }} {{ servicio.fechaInicio }} {{ $t('general.al') }}
						{{ servicio.fechaFin }}
						<span class="fw-bold">: {{ servicio.cantidad }}</span>
					</p>
				</div>
			</template>

			<template v-if="activeView === 'informe'">
				<div>
					<div v-if="formulariosInscripcionBack.informeTotalesHash != null">
						<a :href="baseURL" target="_blank" class="">
							<button class="btn btn-secondary mt-4">{{ $t('botones.descargar') }}</button>
						</a>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script setup>
	import ReservasApiService from '@/services/api/skiandnight/ReservasApiService';
	import Spinner from '../../components/SpinnerLoading.vue';
	import { onBeforeMount, computed, reactive, ref } from 'vue';
	import { useRoute } from 'vue-router';
	import FormularioInscripcionReserva from '@/modules/viajesGrupos/components/FormularioInscripcionReserva.vue';
	import { realizarCheckIn } from '@/services/utils/FormularioInscripcionUtils';
	import { createToaster } from '@meforma/vue-toaster';
	import { proximasExperiencias } from '@/store_pinia/proximasExperiencias';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';

	const storeProxExp = proximasExperiencias();
	const storeHabitacion = informacionHabitacion();

	const toaster = createToaster({ position: 'top-right' });
	const route = useRoute();
	const loading = ref(true);
	const showSpinner = ref(false);
	const error = ref(null);
	const activeView = ref('checkin');
	const baseURL = ref('');
	const formulariosInscripcionBack = ref({});
	const formulariosInscripcionFront = reactive([]);
	const grupoId = route.params.grupoId;

	const anyFormularioShowed = computed(() => formulariosInscripcionFront.some(form => form.showed));

	function updateFormularioHandler(newForm) {
		const idx = formulariosInscripcionFront.findIndex(oriForm => oriForm.id === newForm.id);

		if (idx !== -1) {
			formulariosInscripcionFront[idx] = newForm;
		}
	}

	async function doCheckIn() {
		try {
			showSpinner.value = true;
			if (await realizarCheckIn(formulariosInscripcionFront, toaster, route.params.grupoId, false)) {
				await getInformacionCheckin();
			}
		} finally {
			showSpinner.value = false;
		}
	}

	function getIdsServiciosNoNulos(serviciosWithCantidad) {
		return serviciosWithCantidad.filter(swc => !swc.servicio.nulo).map(swc => swc.servicio.id);
	}

	function getListaServicios(formularioInscripcion) {
		let listaServicios = [];

		formularioInscripcion.forEach(formulario => {
			formulario.serviciosWithCantidad.forEach(swc => {
				const idx = listaServicios.findIndex(s => s.id === swc.servicio.id);
				if (idx !== -1) {
					listaServicios[idx]['cantidad'] += swc.cantidad;
				} else {
					!swc.servicio.nulo && listaServicios.push({ ...swc.servicio, cantidad: swc.cantidad });
				}
			});
		});

		return listaServicios;
	}

	async function getInformacionCheckin() {
		formulariosInscripcionBack.value = await ReservasApiService.getFormularioInscripcion(grupoId);
		formulariosInscripcionBack.value.formularios.forEach(form => formulariosInscripcionFront.push({ id: form.id }));

		baseURL.value =
			process.env.VUE_APP_SKI_AND_NIGHT_BASE_URL +
			'/api/v1/grupos/' +
			route.params.grupoId +
			'/informe/' +
			formulariosInscripcionBack.value.informeTotalesHash;
	}

	async function init() {
		try {
			await storeProxExp.setGrupoSeleccionado(grupoId, false);
			const grupo = storeProxExp.getGrupoSeleccionado;
			await storeHabitacion.loadInformacionViaje(grupo.viaje.id, grupo.viaje.codigo ?? null);
			await getInformacionCheckin();
		} catch (e) {
			console.error(e);
			error.value = true;
		} finally {
			loading.value = false;
		}
	}

	onBeforeMount(() => init());
</script>

<style scoped>
	.container-view {
		max-width: 800px;
		margin: auto;
		padding-top: 15rem;
		min-height: 80vh;
	}

	.atras {
		cursor: pointer;
	}

	.nav-item {
		cursor: pointer;
	}

	@media screen and (max-width: 1021px) {
		.container-view {
			max-width: 800px;
			margin: auto;
			padding: 7rem 1rem 0 1rem;
		}
	}

	.boton-checkin {
		background-color: rgba(var(--bs-primary-rgb), 0.02) !important;
		box-shadow: 0 0 3px 0 rgba(var(--bs-primary-rgb), 0.5) inset !important;
		border: none !important;
		height: 40px !important;
		padding: 0.375rem 0.75rem !important;
		transition: all 0.3s ease;
	}

	.boton-checkin:hover {
		background-color: rgba(var(--bs-primary-rgb), 0.1) !important;
	}
</style>
