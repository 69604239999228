<template>
	<div v-if="grupo">
		<div class="hero-image" :style="{ backgroundImage: `url('${grupo.viaje.imagenExperiencia}')` }"></div>
		<div class="proximas-reservas-detalle">
			<div class="contenedor">
				<div class="row">
					<p class="col text-primary fw-bold fs-4 text-title" style="cursor: pointer" @click="doVolver">
						<i class="fa-solid fa-chevron-left me-2"></i>{{ $t('baseMigas.datosReserva') }}
						{{ grupo.reservas.length > 0 ? grupo.reservas[0].nombreUsuario : '' }}
					</p>
					<div class="col-12 mt-3 p-0">
						<TabsComponent :tabs="reservaTabs" :initial-tab="activeTab" mode="scroll" @tab-changed="handleTabChange" />
					</div>
					<section id="section-personas" class="col-12 section-content">
						<div class="col-12 titulo mb-2">
							{{ $t('reserva.personasHabitacion') }}
						</div>
						<div class="col-12">
							<div v-if="hasAlojamiento && !grupo.viaje.buscador" class="text-end">
								<button class="btn btn-primary" @click="goToCheckIn">
									{{ $t('general.checkInOnline') }}
								</button>
							</div>
							<div class="contenedor-occupancy justify-content-left py-4">
								<CardOccupancy
									:is-card-mode="isMobileView"
									:is-inline-mode="isMobileView"
									:total-occupancies="grupo.habitacion && !grupo.habitacion.isComun ? grupo.habitacion.numPlazas : -1"
									:occupants-info="ocupantes"
									:plazas-libres-repercutidas="grupo.habitacion?.plazasLibresRepercutidas"
									:permitir-compartir="true" />
							</div>

							<CardReservaPlazasLibres
								v-if="mostrarPlazasLibres"
								class="mt-2"
								:plazas-libres="cantidadPlazasLibres"
								:plazas-ocupadas="ocupantes.length"
								:pago-pendiente="totalPendientePlazaLibre"
								:fecha-cancelacion="fechaLimiteToFillRoom"
								@copiar-link="copyUrlUnirse"
								@abonar-pago="handlerAbonarPagoPendiente" />

							<template v-if="grupo.habitacion?.plazasLibresRepercutidas">
								<i18n-t :keypath="'cardReservaPlazasLibres.msjPlazaLibresPagadas'" tag="div">
									<template #plazasLibres>
										<span class="fw-bold">{{ cantidadPlazasLibres }}</span>
									</template>
								</i18n-t>
							</template>
							<CardUrlUnirse
								v-if="!mostrarPlazasLibres && !grupo.viaje.buscador"
								:viaje="grupo.viaje"
								:codigo-grupo="grupo.codigo" />
						</div>
					</section>

					<section id="section-resumen" class="col-12 section-content">
						<div class="col-12 titulo mb-2">
							{{ $t('reserva.resumen') }}
						</div>
						<div class="mt-3 mb-2">
							<CardLateralReserva
								:qr-visible="true"
								:doc-visible="true"
								:grupo-id="grupo.id"
								@recuperar-reserva="showModalRecuperar = true"
								@show-modal-qr="showModalQrReserva = true" />
							<ModalQrReserva
								:grupo="grupo"
								:modal-active="showModalQrReserva"
								@close-modal="showModalQrReserva = false" />
						</div>

						<div class="col-12 mb-3 d-flex row p-0">
							<div class="col-12 col-md-4">
								<button
									v-if="isEnabledModificar() && moreOfAnActiveReservation"
									class="btn btn-link"
									@click="showModalCancelar = true">
									{{ $t('botones.cancelar') }}
								</button>
							</div>
							<div v-if="!grupo.viaje.buscador" class="col-12 col-md-8 text-end p-0">
								<button
									v-if="isEnabledModificar() && moreOfAnActiveReservation"
									class="btn btn-primary"
									@click="
										$router.push({
											name: 'UsuarioProximaReservasModificar',
										})
									">
									{{ $t('botones.modificarReserva') }}
								</button>

								<button
									v-if="!grupo.viaje.infoPago && totalPendiente > 0"
									id="button-pagar"
									class="btn btn-primary"
									@click="abrirModalPagar">
									{{ $t('botones.pagar') }}
								</button>

								<button
									v-else-if="grupo.viaje.infoPago"
									class="btn btn-primary"
									@click="alertAutoCloseWarningWithoutCallback(4000, 'Información de pago: ' + grupo.viaje.infoPago)">
									{{ $t('botones.pagar') }}
								</button>
							</div>
						</div>
						<!-- Sección Informacion de transporte -->
						<template v-if="buses && Object.keys(buses).length !== 0">
							<div class="col-12 titulo mb-2">
								{{ $t('reserva.superGrupo.informacionTransporte') }}
							</div>
							<div class="col-12">
								<div class="tabla-pagos gx-0">
									<table>
										<thead>
											<tr>
												<th>{{ $t('reserva.buses.nombre') }}</th>
												<th>{{ $t('reserva.buses.horaSalidaIda') }}</th>
												<th>{{ $t('reserva.buses.lugarSalidaIda') }}</th>
												<th>{{ $t('reserva.buses.horaSalidaVuelta') }}</th>
												<th>{{ $t('reserva.buses.lugarSalidaVuelta') }}</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(bus, idx) in buses" :key="idx">
												<td>
													{{ bus.nombre }}
													<template v-for="(nombre, idx1) in bus.pasajeros" :key="idx1">
														<div class="text-nombre">
															{{ nombre }}
														</div>
													</template>
												</td>
												<td>{{ bus.horaSalidaIda }}</td>
												<td>{{ bus.lugarSalidaIda }}</td>
												<td>{{ bus.horaSalidaVuelta }}</td>
												<td>{{ bus.lugarSalidaVuelta }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</template>
					</section>
					<!-- Estado del pago de la reserva -->
					<section id="section-estado" class="col-12 section-content">
						<div class="col-12 titulo mb-2">
							{{ $t('reserva.estadoPagoReserva') }}
						</div>
						<div v-if="!(grupo.viaje.hasPagoDiferido || totalPendiente <= 0)" class="col-12 text-red py-2">
							{{
								$t('reserva.msjNoPagos', {
									pendientePago: totalPendiente.toFixed(2),
									currency: agenciaStore.getCurrency,
								})
							}}
						</div>

						<div class="col-12">
							<div class="tabla-pagos gx-0">
								<table>
									<thead>
										<tr>
											<th>{{ $t('reserva.concepto') }}</th>
											<th>{{ $t('general.fecha') }}</th>
											<th class="text-end">{{ $t('general.pagado') }}</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(pago, concepto) in pagosAgrupados" :key="concepto">
											<td>
												<div class="py-2">
													{{ getPagosConcepto(concepto) }}
												</div>

												<template v-for="nombre in pago.nombres" :key="nombre">
													<div class="text-nombre">
														{{ nombre }}
													</div>
												</template>
											</td>
											<td>{{ formatDate(pago.fecha) }}</td>
											<td class="text-secondary text-number text-end">
												{{ pago.total.toFixed(2) }} {{ agenciaStore.getCurrency }}
											</td>
										</tr>

										<tr>
											<td><span class="text-pagado"> </span> {{ $t('subtitulo.total') }} {{ $t('general.pagado') }}</td>
											<td></td>
											<td class="text-secondary text-number text-end">
												{{ grupo.viaje.hasPagoDiferido ? totalPendiente.toFixed(2) : sumaTotalPagos(grupo.reservas) }}
												{{ agenciaStore.getCurrency }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</section>

					<section v-if="grupo.viaje.observaciones" id="section-informacion" class="col-12 section-content">
						<div class="col-12 titulo mb-3">
							{{ $t('reserva.tabs.informacion') }}
						</div>
						<div class="col-12">
							<div id="info-viaje" class="col-12 cuadro-info mb-2" @click="verMas()">
								<span
									v-sanitize-html="
										verMasInfoViaje
											? showOnlyBlockOfText(grupo.viaje.observaciones)
											: showOnlyBlockOfText(grupo.viaje.observaciones, grupo.viaje.observaciones.length)
									" />
								<span class="mas-info">
									<i class="fa-solid fa-circle-info fa-xl"></i>
									{{ verMasInfoViaje ? $t('general.verMenos') : $t('general.masInformacion') }}
								</span>
							</div>
						</div>
					</section>

					<section id="section-comunidad" class="col-12 section-content">
						<div class="col-12 titulo mb-3">
							{{ $t('reserva.tabs.comunidad') }}
						</div>
						<div class="col-12">
							<div class="texto-subtitulo gx-0">
								<div v-if="!grupo.superGrupo" class="mb-4">
									<p>{{ $t('reserva.comunidad.descripcion') }}</p>
									<div class="text-center mb-5 mt-4">
										<button class="btn btn-primary" @click="crearComunidad">
											{{ $t('reserva.comunidad.boton') }}
										</button>
									</div>
								</div>
								<ComunidadCard v-else :grupo="grupo" @eliminar="eliminarComunidad" @copiar-codigo="copiarCodigo" />
							</div>
						</div>
					</section>
				</div>
				<div class="container my-3">
					<div class="row justify-content-center text-center">
						<div class="col">
							<button class="btn btn-primary" @click="doVolver">
								{{ $t('botones.volverReservas') }}
							</button>
						</div>
					</div>
				</div>
				<div v-if="cargando" class="wait">
					<Spinner />
				</div>
			</div>

			<ModalPagarReserva :grupo="grupo" :modal-active="showModalPagar" @close-modal="showModalPagar = false" />

			<ModalRecuperacionReserva
				:grupo="grupo"
				:modal-active="showModalRecuperar"
				@close-modal="showModalRecuperar = false" />

			<ModalCancelarReserva :grupo="grupo" :modal-active="showModalCancelar" @close-modal="showModalCancelar = false" />

			<template v-if="grupo.habitacion">
				<ModalConfirmacionPlazasLibres
					:show="showModalPlazasLibres"
					:plazas-libres="cantidadPlazasLibres"
					:precio-total="totalPendientePlazaLibre"
					@on-close="showModalPlazasLibres = false"
					@on-cancel="handlerAnadirSuplemento"
					@on-submit="handlerGentePorReservar" />
			</template>

			<ModalCrearComunidad
				:modal-active="showModalCrearComunidad"
				@close-modal="showModalCrearComunidad = false"
				@crear-comunidad="handleCrearComunidad" />

			<ModalConfirmacion
				:modal-active="showModalEliminarComunidad"
				:title="t('reserva.comunidad.confirmarEliminar')"
				:message="t('reserva.comunidad.noRecuperar')"
				:confirm-text="t('actions.delete')"
				:cancel-text="t('general.no')"
				@close-modal="showModalEliminarComunidad = false"
				@confirm="confirmarEliminarComunidad" />
		</div>
	</div>
	<div v-else>
		<Spinner />
	</div>
</template>

<script setup>
	import Spinner from '@/components/SpinnerLoading.vue';
	import { useRoute, useRouter } from 'vue-router';
	import { ref, computed, onBeforeMount, onMounted, onUnmounted } from 'vue';
	import { proximasExperiencias } from '@/store_pinia/proximasExperiencias';
	import { showOnlyBlockOfText } from '@/helpers/filters';
	import { alertAutoCloseWarningWithoutCallback } from '@/services/utils/AlertUtils';
	import { createToaster } from '@meforma/vue-toaster';
	import ReservasApiService from '@/services/api/skiandnight/ReservasApiService';
	import ModalPagarReserva from 'src/components/modales/Reserva/ModalPagarReserva.vue';
	import CardOccupancy from '@/modules/viajesGrupos/components/CardOccupancy.vue';
	import CardUrlUnirse from '@/components/card/unirse/CardUrlUnirse.vue';
	import ModalRecuperacionReserva from '@/components/modales/Reserva/ModalRecuperacionReserva';
	import ModalCancelarReserva from '@/components/modales/Reserva/ModalCancelarReserva.vue';
	import ModalConfirmacionPlazasLibres from '@/components/modales/viajesGrupo/ModalConfirmacionPlazasLibres.vue';
	import CardReservaPlazasLibres from '@/components/card/Reserva/CardReservaPlazasLibres.vue';
	import { occupantsNotManagedReservations, occupantsManagedReservations } from '@/services/utils/GrupoUtils';
	import { ESTADOS_RESERVA } from '@/constants/reservaConstants';
	import CardLateralReserva from '@/components/card/Reserva/CardLateralReserva.vue';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import ModalQrReserva from '@/components/modales/Reserva/ModalQrReserva.vue';
	import { calculateTotalPendiente } from '@/services/utils/GrupoUtils';
	import { useI18n } from 'vue-i18n';
	import { CheckIn, UsuarioProximasReservas } from '@/router/RouterNames';
	import moment from 'moment';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import { toRawDeep } from '@/helpers/reactivityUtils';
	import TabsComponent from '@/components/tabs/TabsComponent.vue';
	import ModalCrearComunidad from '@/components/comunidad/ModalCrearComunidad.vue';
	import ModalConfirmacion from '@/components/modales/ModalConfirmacion.vue';
	import ComunidadCard from '@/components/comunidad/ComunidadCard.vue';
	import { formatDate } from '@/helpers/dateUtiles';

	const storeProxExp = proximasExperiencias();
	const storeHabitacion = informacionHabitacion();
	const agenciaStore = agenciaStoreModule();

	const route = useRoute();
	const router = useRouter();
	const showModalRecuperar = ref(false);
	const showModalPagar = ref(false);
	const showModalCancelar = ref(false);
	const grupoId = route.params.grupoId;
	const grupo = ref();
	const buses = ref(null);
	const showModalQrReserva = ref(false);
	const cargando = ref(false);
	const verMasInfoViaje = ref(false);
	const showModalPlazasLibres = ref(false);
	const { t } = useI18n();
	const showModalEliminarComunidad = ref(false);
	const showModalCrearComunidad = ref(false);
	const toaster = createToaster({ position: 'top-right' });

	const activeTab = ref('personas');

	const reservaTabs = computed(() => {
		const tabs = [
			{ id: 'personas', label: t('reserva.tabs.personas') },
			{ id: 'resumen', label: t('reserva.tabs.resumen') },
			{ id: 'estado', label: t('reserva.tabs.estadoPago') },
			{ id: 'comunidad', label: t('reserva.tabs.comunidad') },
		];

		if (grupo.value?.viaje?.observaciones) {
			tabs.splice(3, 0, { id: 'informacion', label: t('reserva.tabs.informacion') });
		}

		return tabs;
	});

	const fechaLimiteToFillRoom = computed(() => {
		if (!mostrarPlazasLibres.value) return null;
		let fechaLimite = moment(grupo.value.viaje.fechaLimitePago);
		if (grupo.value.viaje.daysToFillRoom > 0) {
			const fechaLimiteToFill = moment(grupo.value.habitacion.fechaCreacion).add(
				grupo.value.viaje.daysToFillRoom,
				'days'
			);
			fechaLimite = fechaLimite.isBefore(fechaLimiteToFill) ? fechaLimite : fechaLimiteToFill;
		}
		return fechaLimite.format('YYYY-MM-DD');
	});

	const isMobileView = computed(() => {
		return window.innerWidth <= 768;
	});

	const moreOfAnActiveReservation = computed(
		() => grupo.value.reservas.filter(reserva => reserva.estado !== ESTADOS_RESERVA.CANCELADA).length >= 1
	);

	const hasAlojamiento = computed(() => grupo.value.habitacion);

	const mostrarPlazasLibres = computed(
		() =>
			grupo.value.habitacion &&
			!grupo.value.habitacion.isComun &&
			grupo.value.habitacion.plazasLibresEnabled &&
			!grupo.value.habitacion.plazasLibresRepercutidas &&
			moreOfAnActiveReservation.value &&
			grupo.value.habitacion.numPlazas - ocupantes.value.length > 0 &&
			ocupantes.value.length >= grupo.value.habitacion.numPlazas / 2
	);

	const pagosAgrupados = computed(() => {
		const agrupados = {};
		grupo.value.reservas.forEach(reserva => {
			reserva.pagos.forEach(pago => {
				if (!agrupados[pago.concepto]) {
					agrupados[pago.concepto] = {
						total: 0,
						nombres: [],
						fecha: pago.fecha,
					};
				}
				agrupados[pago.concepto].total += pago.cantidad;
				if (!agrupados[pago.concepto].nombres.includes(reserva.nombreUsuario)) {
					agrupados[pago.concepto].nombres.push(reserva.nombreUsuario);
				}
			});
		});
		return agrupados;
	});

	const totalPendiente = computed(() => calculateTotalPendiente(grupo.value.reservas));

	const ocupantes = computed(() => {
		const ocupantesNoGestionados = occupantsNotManagedReservations(grupo.value);
		const ocupantesGestionados = occupantsManagedReservations(grupo.value);

		return [...ocupantesNoGestionados, ...ocupantesGestionados].map(ocupante => ({
			nombre: ocupante.nombre,
			apellido: ocupante.apellido,
			done: ocupante.done,
		}));
	});

	const totalPendientePlazaLibre = computed(() => {
		if (grupo.value.habitacion) {
			return (
				(grupo.value.habitacion.numPlazas - ocupantes.value.length) * grupo.value.habitacion.alojamiento.precioCamaVacia
			);
		} else {
			return 0;
		}
	});

	const cantidadPlazasLibres = computed(() => {
		if (grupo.value.habitacion) {
			return grupo.value.habitacion.numPlazas - ocupantes.value.length;
		} else {
			return 0;
		}
	});

	function handleTabChange(tabId) {
		activeTab.value = tabId;
	}

	function verMas() {
		verMasInfoViaje.value = !verMasInfoViaje.value;
	}

	function getPagosConcepto(concepto) {
		return concepto.includes('.') ? t(`reserva.pagos.${concepto}`) : concepto;
	}

	function sumaTotalPagos(reservas) {
		let sum = 0;
		reservas.map(({ pagos }) => {
			pagos.map(({ cantidad }) => {
				sum += cantidad;
			});
		});
		return sum.toFixed(2);
	}

	function doVolver() {
		router.push({
			name: UsuarioProximasReservas,
		});
	}

	function goToCheckIn() {
		router.push({
			name: CheckIn,
			params: {
				grupoId: grupoId,
				reservaId: grupo.value.reservas[0].id,
			},
		});
	}

	function isEnabledModificar() {
		if (grupo.value?.viaje?.fechaInicio) {
			let date = new Date(grupo.value.viaje.fechaInicio);
			let today = new Date();
			return date > today;
		}
		return true;
	}

	async function copyUrlUnirse() {
		try {
			const dominioActual = window.location.origin;
			let url = `${dominioActual}/grupos/viajes/${grupo.value.viaje.id}/unirse/${grupo.value.codigo}`;
			let urlFinal = grupo.value.viaje.codigo ? url + `?codigo=${grupo.value.viaje.codigo.toUpperCase()}` : url;

			await navigator.clipboard.writeText(urlFinal);
			toaster.success(t('general.clipboard.success'));
		} catch (err) {
			toaster.error(t('general.clipboard.error'));
		}
	}

	function handlerAbonarPagoPendiente() {
		ReservasApiService.doForzarPlazasLibres(grupoId)
			.then(() => {
				getInformacionGrupo(grupoId).finally(async () => {
					await init();
					if (!grupo.value.viaje.infoPago && totalPendiente.value > 0) {
						document.getElementById('button-pagar').click();
					}
				});
			})
			.catch(err => {
				toaster.error(err);
			});
	}

	async function getInformacionGrupo(grupoId) {
		await storeProxExp.setGrupoSeleccionado(grupoId, false);
		grupo.value = storeProxExp.getGrupoSeleccionado;
		try {
			buses.value = await ReservasApiService.getBusesGrupo(grupoId);
		} catch (err) {
			console.log(err);
		}
	}

	function abrirModalPagar() {
		if (mostrarPlazasLibres.value) {
			showModalPlazasLibres.value = true;
		} else {
			showModalPagar.value = true;
		}
	}

	function handlerAnadirSuplemento() {
		showModalPlazasLibres.value = false;
		handlerAbonarPagoPendiente();
	}

	function handlerGentePorReservar() {
		showModalPlazasLibres.value = false;
		showModalPagar.value = true;
	}

	async function init() {
		await getInformacionGrupo(grupoId);
		storeHabitacion.clearReservas();
		const grupoRaw = toRawDeep(grupo.value);
		storeHabitacion.setInformacionAlojamientoFromRGrupoDTO(grupoRaw);

		if (grupoRaw.viaje && grupoRaw.viaje.id) {
			await storeHabitacion.loadInformacionViaje(grupoRaw.viaje.id, grupoRaw.viaje.codigo ?? null);
		}

		grupoRaw.reservas.map(reserva => {
			reserva.usuario = {
				nombre: reserva.nombre,
				apellidos: reserva.apellidos,
				apellido: reserva.apellidos,
				isMenor: reserva.isMenor,
			};
			storeHabitacion.addReserva(reserva);
		});
	}

	function crearComunidad() {
		showModalCrearComunidad.value = true;
	}

	async function handleCrearComunidad(nombre) {
		try {
			cargando.value = true;
			await ReservasApiService.doCrearSuperGrupo(grupoId, { nombre });
			toaster.success(t('reserva.comunidad.creacionExitosa'));
			showModalCrearComunidad.value = false;
			await getInformacionGrupo(grupoId);
		} catch (err) {
			toaster.error(err.response?.data?.message || t('reserva.comunidad.errorCrear'));
		} finally {
			cargando.value = false;
		}
	}

	function copiarCodigo(codigo) {
		const dominioActual = window.location.origin;
		const url = `${dominioActual}/comunidad/${grupoId}/unirse/${codigo}`;

		navigator.clipboard
			.writeText(url)
			.then(() => {
				toaster.success(t('general.clipboard.success'));
			})
			.catch(() => {
				toaster.error(t('general.clipboard.error'));
			});
	}

	function eliminarComunidad() {
		showModalEliminarComunidad.value = true;
	}

	async function confirmarEliminarComunidad() {
		try {
			cargando.value = true;
			await ReservasApiService.doEliminarSuperGrupo(grupoId);
			toaster.success(t('reserva.comunidad.eliminacionExitosa'));
			await getInformacionGrupo(grupoId);
		} catch (err) {
			toaster.error(err.response?.data?.message || t('reserva.comunidad.errorEliminar'));
		} finally {
			cargando.value = false;
		}
	}

	onMounted(() => {
		window.addEventListener('resize', () => {
			isMobileView.value = window.innerWidth <= 768;
		});
	});

	onUnmounted(() => {
		window.removeEventListener('resize', () => {
			isMobileView.value = window.innerWidth <= 768;
		});
	});

	onBeforeMount(async () => {
		await init();
	});
</script>

<style lang="scss" scoped>
	.hero-image {
		width: 100vw;
		height: 290px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;
	}

	.proximas-reservas-detalle {
		background-color: white;
		min-height: 80vh;
		color: var(--bs-primary);
		font-weight: 400;
		font-size: 0.8rem;
		max-width: 71.8vw;
		margin: 0 auto;
		padding: 2rem 0;

		@media screen and (max-width: 768px) {
			max-width: 100%;
			margin: 0 12px;
		}
	}

	@media screen and (max-width: 768px) {
		.contenedor {
			margin: 0 12px;
		}

		.titulo {
			font-size: 16px !important;
		}

		.tabla-pagos {
			thead {
				font-size: 12px !important;
			}

			font-size: 11px !important;
		}
		.text-number {
			font-size: 11px !important;
		}

		.btn-primary {
			font-size: 13px !important;
		}

		.btn-link {
			font-size: 12px !important;
		}
		.text-grupo {
			font-size: 14px !important;
		}
	}

	.wait {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 100;
		background-color: rgb(154, 154, 154, 0.5);
		padding-top: 40vh;
	}

	.proximas-reservas-detalle {
		background-color: white;
		min-height: 80vh;
		background-size: contain;
		background-position: top;
		color: var(--bs-primary);
		font-weight: 400;
		font-size: 0.8rem;
	}

	.titulo {
		font-size: 20px;
		font-weight: 600;
	}

	@media (min-width: 576px) {
		.modal-dialog {
			max-width: 800px;
			margin: 1.75rem auto;
		}
	}

	@media (min-width: 1000px) {
		.proximas-reservas-detalle {
			max-width: 71.8vw !important;
		}
	}

	.tabla-pagos {
		background-color: #f9f7f8;
		border-radius: var(--bs-border-radius-xl);
		font-size: 14px;
		padding: 0.5rem 1.25rem 1.25rem 1.25rem;

		font-size: 15px;
		font-weight: 400;

		table {
			width: 100%;
		}

		thead {
			font-size: 16px;
			font-weight: 500;
		}

		div {
			padding-bottom: 1rem;
			padding-right: 1rem;
		}

		th {
			border-bottom: 0.5px solid var(--bs-primary);
			font-size: 16px;
			font-weight: 500;
		}

		td {
			padding: 0.6rem 0.4rem;
			vertical-align: top;

			.text-nombre {
				font-size: 12px;
				font-weight: 400;
				padding: 0 0 0 10px;
			}

			.text-pagado {
				font-size: 15px;
				font-weight: 500;
			}
		}

		th {
			padding: 0.75rem;
			vertical-align: top;
		}
	}

	.cuadro-info {
		background-color: #f9f7f8;
		width: 100%;
		border-radius: var(--bs-border-radius-xl);
		padding: 1rem;
		margin: 0 auto;
	}

	.mas-info {
		float: right;
		cursor: pointer;
		font-size: 0.7rem;
		font-weight: 400;
	}

	.icono-mas-info {
		width: 1rem;
		margin-left: 0.7rem;
		margin-bottom: 0.5px;
	}

	.boton-supergrupo {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 0.5rem;
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		background-color: var(--bs-secondary);
		box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.5), -0.1rem -0.1rem 0.2rem rgba(255, 255, 255, 0.5);
		transition: all 0.2s ease;
		border: 0;
	}

	.icono-supergrupo {
		width: 1.5rem;
		margin-bottom: 0.5rem;
	}

	.boton-supergrupo:hover {
		box-shadow: 0.1rem 0.1rem 0.2rem rgba(255, 255, 255, 0.5), -0.1rem -0.1rem 0.2rem rgba(0, 0, 0, 0.5);
	}

	.boton-supergrupo:active {
		background-color: var(--bs-secondary);
	}

	.dropdown-items-supergrupo {
		margin-top: -1rem !important;
		margin-left: 1rem !important;
		padding: 0.5rem;
		font-size: 0.9rem;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);

		.dropdown-item {
			padding: 0 0.5rem;
		}
	}

	.row {
		padding-bottom: 0;
	}

	.text-occupancies {
		font-size: 18px;
		font-weight: 400;
	}

	.btn-primary {
		text-align: center;
		font-size: 14px;
		font-weight: 500;
	}

	.btn-link {
		text-align: center;
		font-size: 16px;
		font-weight: 600;
	}

	.text-number {
		font-size: 15px;
		font-weight: 700;
	}

	.text-grupo {
		font-size: 15px;
		font-weight: 400;
	}

	.text-red {
		color: #f00;
		font-size: 14px;
		font-weight: 400;
	}

	.tabs-wrapper {
		margin-bottom: 2rem;
	}

	.section-content {
		scroll-margin-top: 120px;
		padding: 1rem 0;
	}

	.tabs-wrapper {
		margin-bottom: 2rem;
		position: sticky;
		top: 0;
		background: white;
		z-index: 10;
	}

	.texto-subtitulo {
		font-size: 15px;
	}

	.comunidad-card {
		border: 1px solid var(--bs-border-color);
		background: white;
	}

	.link-compartir {
		.bg-light {
			background-color: #f8f9fa;
		}
	}

	.miembros {
		.avatar {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: var(--bs-primary);
			color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
		}

		.plaza-libre {
			font-size: 0.875rem;
			color: var(--bs-secondary);
		}
	}

	.btn-danger {
		background-color: #dc3545;
		border-color: #dc3545;
		color: white;

		&:hover {
			background-color: #bb2d3b;
			border-color: #b02a37;
		}
	}

	@media screen and (max-width: 768px) {
		.hero-image {
			display: none;
		}

		.proximas-reservas-detalle {
			padding-top: 12vh !important;
		}

		.titulo {
			font-size: 14px !important;
		}

		.texto-subtitulo {
			font-size: 13px;
		}
	}
</style>
