<template>
	<div class="row">
		<div class="col">
			<div class="input-group gap-3 mb-1">
				<select
					:value="servicioWithCantidadSeleccionado.servicio.id"
					class="form-select selector-servicio text-primary translate"
					:class="{ 'rounded-end': true }"
					:disabled="!isServicioDescontratable(reserva, servicioWithCantidadSeleccionado.servicio)"
					@change="handleServicioSeleccionadoChange">
					<option
						v-for="servicio in categoriaServicios.servicios
							.filter(s => s.activo || isServicioSeleccionado(s))
							.sort((s1, s2) => s1.precio - s2.precio)"
						:key="servicio.id"
						:value="servicio.id"
						:disabled="!isServicioSeleccionable(servicio)">
						{{ servicio.nombre }}
						<template v-if="!servicio.nulo">
							({{ $t('general.del') }}
							{{ formatDateDDmm(servicio.fechaInicio) }}
							{{ $t('general.al') }} {{ formatDateDDmm(servicio.fechaFin) }})
						</template>
						<template v-if="servicio.precio && isServicioSeleccionable(servicio)">
							(<template v-if="servicio.precio > 0">+</template>{{ servicio.precio }}{{ agenciaStore.getCurrency }})
						</template>
						<template v-if="!isServicioSeleccionable(servicio)">({{ $t('general.agotado') }})</template>
					</option>
				</select>
				<div v-if="!servicioWithCantidadSeleccionado.servicio.nominal">
					<InputSelectorCantidad
						:disabled="!isServicioContratable(reserva, servicioWithCantidadSeleccionado.servicio)"
						:count="servicioWithCantidadSeleccionado.cantidad"
						:min-count="
							isServicioDescontratable(reserva, servicioWithCantidadSeleccionado.servicio)
								? 1
								: servicioWithCantidadSeleccionado.cantidadOriginal
						"
						:max-count="calculaMaxCantidadForServicio(servicioWithCantidadSeleccionado.servicio)"
						:real-max-count="calculaMaxRealCantidadForServicio(servicioWithCantidadSeleccionado.servicio)"
						@update-count="handleCantidadServicioSeleccionadoChange" />
				</div>
			</div>
			<div class="row text-primary mb-2 description-text">
				<template v-if="servicioWithCantidadSeleccionado && !servicioWithCantidadSeleccionado.servicio.nulo">
					<div class="col-12 translate">
						{{ servicioWithCantidadSeleccionado.servicio.descripcion }}
					</div></template
				>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { formatDateDDmm } from '@/helpers/dateUtiles';
	import { isServicioContratable, isServicioDescontratable } from '@/helpers/serviciosUtils';
	import { onBeforeMount, ref, computed } from 'vue';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import InputSelectorCantidad from '@/components/inputs/InputSelectorCantidad';
	import { toRawDeep } from '@/helpers/reactivityUtils';

	const props = defineProps({
		idxReserva: {
			type: Number,
			default: null,
		},
		categoriaServicios: {
			type: Object, // VGServiciosCategorizadosDTO
			default: null,
		},
	});

	const emits = defineEmits(['servicioSeleccionado']);

	const storeHabitacion = informacionHabitacion();
	const agenciaStore = agenciaStoreModule();

	const servicioWithCantidadSeleccionado = ref();

	const reserva = computed(() => storeHabitacion.getReservaByIdx(props.idxReserva));

	function isServicioSeleccionado(servicio) {
		return servicioWithCantidadSeleccionado.value && servicioWithCantidadSeleccionado.value.servicio.id === servicio.id;
	}

	function isServicioSeleccionable(servicio) {
		return (
			isServicioContratable(reserva.value, servicio) &&
			(isServicioSeleccionado(servicio) || servicio.nulo || calculaMaxCantidadForServicio(servicio) > 0)
		);
	}

	function calculaMaxRealCantidadForServicio(servicio) {
		let cantidadActual = 0;
		const currentServicioCategorizado = findServicioCategorizado(servicio.id);
		const servicioWithCantidadSeleccionado = reserva.value.serviciosWithCantidad[servicio.tipo];
		if (
			servicio.cuposMetasId?.some(cupoId => servicioWithCantidadSeleccionado.servicio.cuposMetasId?.includes(cupoId))
		) {
			cantidadActual = servicioWithCantidadSeleccionado.cantidad;
		}
		return cantidadActual + currentServicioCategorizado.numCupos;
	}

	function calculaMaxCantidadForServicio(servicio) {
		return Math.min(calculaMaxRealCantidadForServicio(servicio), 10);
	}

	function addServicioToReserva(isModificacion = false) {
		storeHabitacion.addServicioToReserva(
			props.idxReserva,
			servicioWithCantidadSeleccionado.value.servicio,
			servicioWithCantidadSeleccionado.value.cantidad,
			isModificacion
		);
		emits('servicioSeleccionado', servicioWithCantidadSeleccionado.value);
	}

	function handleCantidadServicioSeleccionadoChange(newCantidad) {
		servicioWithCantidadSeleccionado.value.cantidad = newCantidad;
		addServicioToReserva(true);
		if (servicioWithCantidadSeleccionado.value.cantidad < servicioWithCantidadSeleccionado.value.oldCantidad) {
			storeHabitacion.incrementCupoServicios(servicioWithCantidadSeleccionado.value.servicio);
		} else {
			storeHabitacion.decrementCupoServicios(servicioWithCantidadSeleccionado.value.servicio);
		}
		servicioWithCantidadSeleccionado.value.oldCantidad = newCantidad;
	}

	function handleServicioSeleccionadoChange(event) {
		servicioWithCantidadSeleccionado.value.servicio = toRawDeep(findServicioCategorizado(parseInt(event.target.value)));
		servicioWithCantidadSeleccionado.value.servicio.justSelected = true;
		servicioWithCantidadSeleccionado.value.cantidad = 1;
		servicioWithCantidadSeleccionado.value.cantidadOriginal = 1;
		addServicioToReserva(true);
		if (!servicioWithCantidadSeleccionado.value.servicio.nulo) {
			storeHabitacion.decrementCupoServicios(servicioWithCantidadSeleccionado.value.servicio);
		}
		if (
			servicioWithCantidadSeleccionado.value.oldServicio &&
			!servicioWithCantidadSeleccionado.value.oldServicio.nulo
		) {
			storeHabitacion.incrementCupoServicios(
				servicioWithCantidadSeleccionado.value.oldServicio,
				servicioWithCantidadSeleccionado.value.oldCantidad
			);
		}
		servicioWithCantidadSeleccionado.value.oldServicio = servicioWithCantidadSeleccionado.value.servicio;
		servicioWithCantidadSeleccionado.value.oldCantidad = 1;
	}

	function findServicioCategorizado(servicioId) {
		return props.categoriaServicios.servicios.find(s => s.id === servicioId);
	}

	function init() {
		const categoria = props.categoriaServicios.categoria;
		const reservaCurrentServicioWithCantidad = reserva.value.serviciosWithCantidad[categoria];
		const servicioPB = props.categoriaServicios.servicios.find(({ paqueteBasico }) => paqueteBasico) || null;
		// If the booking does not have any service of this category needs to be initialised
		if (reservaCurrentServicioWithCantidad === undefined) {
			const servicio = props.categoriaServicios.servicios.reduce((accum, s) => {
				if ((s.paqueteBasico && s.numCupos > 0) || (accum == null && s.nulo)) {
					return s;
				} else {
					return accum;
				}
			}, null);
			servicioWithCantidadSeleccionado.value = {
				servicio: servicio,
				oldServicio: servicio,
				cantidad: 1,
				oldCantidad: 1,
				cantidadOriginal: 1,
				categoriaPaqueteBasico: servicioPB !== null,
				servicioPB: servicioPB,
			};
			storeHabitacion.decrementCupoServicios(servicioWithCantidadSeleccionado.value.servicio);
		} else {
			// Otherwise the current value is load
			const currentServicioCategorizado = findServicioCategorizado(reservaCurrentServicioWithCantidad.servicio.id);
			servicioWithCantidadSeleccionado.value = {
				servicio: currentServicioCategorizado,
				oldServicio: currentServicioCategorizado,
				cantidad: reservaCurrentServicioWithCantidad.cantidad,
				oldCantidad: reservaCurrentServicioWithCantidad.cantidad,
				cantidadOriginal: reservaCurrentServicioWithCantidad.cantidad,
				categoriaPaqueteBasico: servicioPB !== null,
				servicioPB: servicioPB,
			};
		}
		addServicioToReserva();
	}

	onBeforeMount(() => {
		init();
	});
</script>

<style lang="scss" scoped>
	.selector-servicio {
		background-color: rgba(var(--bs-primary-rgb), 0.02);
		box-shadow: 0 0 3px 0 rgba(var(--bs-primary-rgb), 0.35) inset;
	}

	.description-text {
		font-size: 12px;
		font-weight: 400;
		margin-top: 0.4rem;

		@media only screen and (max-width: 768px) {
			font-size: 10px;
		}
	}
</style>
