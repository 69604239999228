import SkiAndNightApiService from './BaseSkiAndNightApiService';

const HOME_API_VERSION = 'v1';
const HOME_API_BASEPATH_URL = HOME_API_VERSION + '/grupos';

class ReservasApiService {
	async getProximasExperiencias() {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL);
	}

	async getGrupo(grupoId, draft = false) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/' + grupoId + '?draft=' + draft));
	}

	async getGrupoByCodigo(codigo) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/' + codigo));
	}

	async getReservaDetalleUsuario(reservaId, email) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat(`/${reservaId}/detalle/${email}`));
	}

	async doModificarServiciosReservasGrupo(grupoId, modificarReservaBody) {
		return await SkiAndNightApiService.post(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/servicios`),
			modificarReservaBody
		);
	}

	async doForzarPlazasLibres(grupoId) {
		return await SkiAndNightApiService.post(HOME_API_BASEPATH_URL.concat(`/${grupoId}/forzarPlazasLibres`));
	}

	async doEnviarCodigoGrupo(grupoId, info) {
		return await SkiAndNightApiService.post(HOME_API_BASEPATH_URL.concat(`/${grupoId}/enviarCodigo`), info);
	}

	async doCancelarReservasGrupo(grupoId, listaReservasCancelar, motivoCancelacionId, explicacionCancelacion) {
		return await SkiAndNightApiService.post(HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/cancelar`), {
			reservasId: listaReservasCancelar,
			motivoCancelacionId,
			explicacionCancelacion,
		});
	}

	async doRecuperarReservasGrupo(grupoId, listaReservasRecuperar) {
		return await SkiAndNightApiService.post(HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/recuperar`), {
			reservasId: listaReservasRecuperar,
		});
	}

	async getBusesGrupo(grupoId) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat(`/${grupoId}/buses`));
	}

	async getBonoAdjunto(grupoId, reservaId) {
		return await SkiAndNightApiService.get(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/${reservaId}/bonoAdjunto`)
		);
	}

	async getMotivosCancelacion() {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/motivosCancelacion'));
	}

	async realizarCheckIn(reservaId, grupoId, reservaForm) {
		return await SkiAndNightApiService.post(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/${reservaId}/checkIn`),
			reservaForm
		);
	}

	async getServicioToValidar(grupoId, reservaId, codigoValidacion) {
		return await SkiAndNightApiService.get(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/${reservaId}/servicios/${codigoValidacion}`)
		);
	}

	async doValidarServicio(grupoId, reservaId, codigoValidacion) {
		return await SkiAndNightApiService.post(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/${reservaId}/servicios/${codigoValidacion}/validar`)
		);
	}

	async getFormularioInscripcion(grupoId) {
		return await SkiAndNightApiService.get(HOME_API_BASEPATH_URL.concat('/' + grupoId + '/formulariosInscripcion'));
	}

	async postFormularioCheckIn(grupoId, reservaId, formData) {
		return await SkiAndNightApiService.post(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/${reservaId}/checkIn`),
			formData,
			{},
			{ 'Content-Type': 'multipart/form-data' }
		);
	}

	async postFormularioDatosPersonales(grupoId, reservaId, datosPersonalesForm) {
		return await SkiAndNightApiService.post(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/${reservaId}/datosPersonales`),
			datosPersonalesForm
		);
	}

	async postFormularioReferidos(grupoId, reservaId, formDataReferidos) {
		return await SkiAndNightApiService.post(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/${reservaId}/referidos`),
			formDataReferidos
		);
	}

	async postFormularioPreguntas(grupoId, reservaId, formDataPreguntas) {
		return await SkiAndNightApiService.post(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/reservas/${reservaId}/preguntas`),
			formDataPreguntas
		);
	}

	async doCrearSuperGrupo(grupoId, infoSuperGrupo) {
		return await SkiAndNightApiService.post(HOME_API_BASEPATH_URL.concat(`/${grupoId}/supergrupo`), infoSuperGrupo);
	}

	async doUnirseSuperGrupo(grupoId, infoSuperGrupo) {
		return await SkiAndNightApiService.post(
			HOME_API_BASEPATH_URL.concat(`/${grupoId}/supergrupo/unir`),
			infoSuperGrupo
		);
	}

	async doCompartirSuperGrupo(grupoId, info) {
		return await SkiAndNightApiService.post(HOME_API_BASEPATH_URL.concat(`/${grupoId}/supergrupo/enviarCodigo`), info);
	}

	async doSalirSuperGrupo(grupoId) {
		return await SkiAndNightApiService.post(HOME_API_BASEPATH_URL.concat(`/${grupoId}/supergrupo/salir`));
	}

	async doExpulsarGrupoSuperGrupo(grupoId, grupoAExpulsar) {
		return await SkiAndNightApiService.post(HOME_API_BASEPATH_URL.concat(`/${grupoId}/supergrupo/expulsar`), {
			grupo: grupoAExpulsar,
		});
	}

	async doEliminarSuperGrupo(grupoId) {
		return await SkiAndNightApiService.delete(HOME_API_BASEPATH_URL.concat(`/${grupoId}/supergrupo/eliminar`));
	}

	async getSupergrupo(grupoId, codigo) {
		const response = await SkiAndNightApiService.get(`${HOME_API_BASEPATH_URL}/${grupoId}/supergrupo`, {
			codigo,
		});
		return response;
	}
}

export default new ReservasApiService();
