import { defineStore } from 'pinia';
import ViajesGrupoApiService from '@/services/api/skiandnight/ViajesGrupoApiService';
import { getPaqueteBasicoForServicio, getServicioUniqueId } from '@/helpers/serviciosUtils';
import {
	calculaDescuento,
	calculaDuracionViaje,
	calculaPrecioBase,
	calculaPrecioComplementos,
	calculaPrecioServicios,
	calculaPrecioGastos,
	calculaPrecioForServicioWithCantidad,
} from '@/helpers/viajesGrupos';
import { max, min } from '@popperjs/core/lib/utils/math';
import { ESTADOS_RESERVA, TIPO_TASA } from '@/constants/reservaConstants';
import currency from 'currency.js';
import { toRawDeep } from '@/helpers/reactivityUtils';
import { readonly } from 'vue';

export const informacionHabitacion = defineStore('informacionHabitacion', {
	state: () => ({
		reservas: [],
		//@deprecated
		precioBase: 0,
		//@deprecated
		descuento: 0,
		codigoDescuento: null,
		serviciosCategorizado: [], //VGServiciosCategorizadosDTO
		_cuposMetaServicios: {},
		loading: true,
		filtros: null,
		infoAlaquiler: {},
		infoClases: {},
		dataAlquiler: [],
		dataClases: [],
		infoAlquilerNulo: {
			nombre: null,
		},
		infoClasesNulo: null,
		informacionGrupo: {},
		informacionAlojamiento: {
			nombre: '',
			estrellas: 0,
			poblacion: '',
			cupos: [
				{
					tipo: {
						nombre: '',
					},
					plazas: 0,
					regimen: {
						nombre: '',
					},
				},
			],
			habitacion: [
				{
					codigo: '',
					id: 0,
					numPlazas: 0,
					numPlazasOcupadas: 0,
					plazas: 0,
					tipo: { id: 0, nombre: '' },
				},
			],
		},
		reservaRecuperada: null,
		tiposGruposViajes: [],
		informacionViaje: {},
	}),
	getters: {
		//todo: Remove safely
		getTotalReserva(state) {
			let sum = currency(0);
			if (state.getResumenReservas) {
				state.getResumenReservas.map(res => {
					if (res.habilitarReserva || res.habilitarReserva == undefined) {
						sum = sum.add(res.suma);
						if (state.informacionAlojamiento) {
							sum = sum
								.add(state.informacionAlojamiento.incremento)
								.add(state.informacionAlojamiento.habitacion.precioTotal);
						}
					}
				});
			}
			return sum;
		},
		getCodigoDescuento(state) {
			return state.codigoDescuento;
		},
		getViaje(state) {
			return state.informacionViaje;
		},
		getAlojamiento(state) {
			return state.informacionAlojamiento;
		},
		getGrupo(state) {
			return state.informacionGrupo;
		},
		getServicios(state) {
			return readonly(state.serviciosCategorizado);
		},
		getReservaById: state => {
			return reservaId => state.reservas.find(({ id }) => id === reservaId);
		},
		getReservaByIdx: state => {
			return reservaIdx => state.reservas[reservaIdx];
		},
		getReservaIdxById: state => {
			return reservaId => state.reservas.findIndex(({ id }) => id === reservaId);
		},
		getPrecioServiciosReserva: state => {
			const getReservaIdxById = this.getReservaIdxById(state);
			return reservaId => {
				let precio = currency(0);
				let reserva = getReservaIdxById(reservaId);
				Object.keys(reserva.serviciosWithCantidad).forEach(key => {
					const servicioWithCantidad = reserva.serviciosWithCantidad[key];
					precio = precio.add(currency(servicioWithCantidad.servicio.precio).multiply(servicioWithCantidad.cantidad));
				});
				return precio;
			};
		},
		calculaPrecioBaseGrupo(state) {
			let precioBase = currency(state.informacionViaje.precioBase);
			if (state.informacionAlojamiento) {
				precioBase = precioBase
					.add(state.informacionAlojamiento.habitacion.precioTotal)
					.add(state.informacionAlojamiento.incremento);
			}
			return precioBase;
		},

		getFeeTotalPlataforma(state) {
			return state.informacionViaje.repercutirFee ? state.informacionViaje.fee : 0;
		},
		getReservas(state) {
			return state.reservas;
		},
		getResumenReservas(state) {
			if (state.reservas.length === 0) return [];
			return state.reservas.map(reserva => {
				let reservaAux = {
					reservaPropia: false,
					tasasExternasCalculadas: [],
					tasasInternasCalculadas: [],
					usuario: null,
					nombreUsuario: '',
					precioBaseViaje: state.informacionViaje.precioBase,
					precioTotalCupo: state.informacionAlojamiento?.habitacion.precioTotal || 0,
					incrementoHotel: state.informacionAlojamiento?.incremento || 0,

					// todo: Remove safely
					suma: state.precioBase,
					...reserva,
				};
				reservaAux.precioServicios = calculaPrecioServicios(reservaAux);
				reservaAux.precioComplementos = calculaPrecioComplementos(reservaAux);
				reservaAux.precioGastos = calculaPrecioGastos(reservaAux);
				reservaAux.precioBase = calculaPrecioBase(reservaAux);
				reservaAux.precioTotal = reservaAux.precioBase.add(reservaAux.precioGastos);
				reservaAux.precioTotalSinTasasInternas = reservaAux.precioTotal;

				if (state.codigoDescuento !== null) {
					const totalPendiente = max(0, reservaAux.precioBase.subtract(reservaAux.totalPagado).value);
					reservaAux.descuento = currency(
						min(totalPendiente, calculaDescuento(state.codigoDescuento, reservaAux.precioBase).value)
					);
				} else {
					reservaAux.descuento = currency(0);
				}

				if (
					state.informacionAlojamiento?.tasaTuristica &&
					state.informacionAlojamiento?.tasaTuristica !== 0 &&
					(reservaAux.usuario.isMenor === undefined || !reservaAux.usuario.isMenor)
				) {
					let numDays = calculaDuracionViaje(state.informacionViaje);
					let precioTasaTuristica = currency(state.informacionAlojamiento.tasaTuristica).multiply(numDays);
					reservaAux.precioTotalSinTasasInternas = reservaAux.precioTotalSinTasasInternas.add(precioTasaTuristica);
					reservaAux.precioTotal = reservaAux.precioTotal.add(precioTasaTuristica);
					reservaAux.tasasExternasCalculadas.push({
						importe: precioTasaTuristica,
						codigo: TIPO_TASA.TASA_TURISTICA,
					});
				}

				const tasaTotal = this.getFeeTotalPlataforma;

				if (tasaTotal !== 0) {
					//Tasa gestion plataforma
					let precioTasaPlataforma = 0;
					//SI es de NUEVA CREACION o BLOQUEADA, la tasa se autocalcula
					//SI es MODIFICACION DE RESERVA, se mantiene
					if (!reservaAux.estado || reservaAux.estado === ESTADOS_RESERVA.BLOQUEADA) {
						precioTasaPlataforma = reservaAux.precioBase.multiply(tasaTotal);
					} else {
						const tasaPlataforma = reservaAux.tasasInternas.find(
							tasa => tasa.codigo === TIPO_TASA.TASA_GESTION_PLATAFORMA
						);
						precioTasaPlataforma = currency(tasaPlataforma ? tasaPlataforma.importe : 0);
					}
					reservaAux.precioTotal = reservaAux.precioTotal.add(precioTasaPlataforma);
					reservaAux.tasasInternasCalculadas.push({
						importe: precioTasaPlataforma,
						codigo: TIPO_TASA.TASA_GESTION_PLATAFORMA,
					});

					//Tasa gestion cambio
					if (reservaAux.tasasInternas) {
						let sumaTasasCambioBase = reservaAux.tasasInternas
							.filter(tasa => tasa.codigo === TIPO_TASA.TASA_GESTION_CAMBIO)
							.reduce((sum, tasa) => sum.add(tasa.importe), currency(0));
						reservaAux.precioTotal = reservaAux.precioTotal.add(sumaTasasCambioBase);
						if (sumaTasasCambioBase.value > 0) {
							reservaAux.tasasInternasCalculadas.push({
								importe: sumaTasasCambioBase,
								codigo: TIPO_TASA.TASA_GESTION_CAMBIO,
							});
						}
					}

					if (reservaAux.deltaCambioTmp && reservaAux.deltaCambioTmp.value > 0) {
						const tasaCambioExist = reservaAux.tasasInternasCalculadas.find(
							tasa => tasa.codigo === TIPO_TASA.TASA_GESTION_CAMBIO
						);
						reservaAux.precioTotal = reservaAux.precioTotal.add(reservaAux.deltaCambioTmp);
						if (tasaCambioExist) {
							tasaCambioExist.importe = tasaCambioExist.importe.add(reservaAux.deltaCambioTmp);
						} else {
							reservaAux.tasasInternasCalculadas.push({
								importe: currency(reservaAux.deltaCambioTmp),
								codigo: TIPO_TASA.TASA_GESTION_CAMBIO,
							});
						}
					}
				}

				// todo: Analyse if this could be removed
				reservaAux.serviciosWithCantidad = Object.keys(reserva.serviciosWithCantidad).map(key => {
					const servicioWithCantidad = reserva.serviciosWithCantidad[key];
					reservaAux.suma = currency(servicioWithCantidad.servicio.precio)
						.multiply(servicioWithCantidad.cantidad)
						.add(reservaAux.suma);
					return servicioWithCantidad;
				});
				return reservaAux;
			});
		},
		getUsuariosReserva(state) {
			return state.reservas.map(reserva => {
				return {
					nombre: reserva.usuario?.nombre || '',
					apellido: reserva.usuario?.apellido || '',
					done: false,
				};
			});
		},
		getNombresReserva(state) {
			return state.reservas.map((res, index) => {
				if (res['usuario'] === undefined) {
					return {
						id: res.id,
						nombre: res.usuarioNombre,
						index: index,
					};
				}
				return { id: res.id, nombre: res.usuario.nombre, index: index };
			});
		},
	},
	actions: {
		setCodigoDescuento(codigoDescuentoData) {
			this.codigoDescuento = codigoDescuentoData;
		},
		addNotPersistedReserva(reservaData) {
			let data = {
				persisted: false,
				serviciosWithCantidad: {},
				gastos: [],
				complementos: [],
				...reservaData,
			};
			this.reservas.push(data);
		},
		deleteReserva(id) {
			this.reservas.splice(
				this.reservas.findIndex(data => data.id === id),
				1
			);
		},
		async loadInformacionViaje(viajeId, codigoViaje = null, forceReloadServicio = false) {
			if (
				this.informacionViaje === undefined ||
				Object.keys(this.informacionViaje).length === 0 ||
				this.informacionViaje.id !== viajeId
			) {
				this.loading = true;
				try {
					await Promise.all([
						ViajesGrupoApiService.getViaje(viajeId, codigoViaje).then(res => {
							this.informacionViaje = res;
						}),
						ViajesGrupoApiService.getServicios(viajeId).then(res => {
							this.serviciosCategorizado = res.serviciosCategorizados;
							this._cuposMetaServicios = Object.values(this.serviciosCategorizado)
								.reduce((accum, sc) => accum.concat(sc.servicios), [])
								.reduce((accum, servicio) => {
									if (!servicio.nulo) {
										servicio.cuposMetasId.forEach(cupoId => {
											if (accum[cupoId] === undefined) {
												accum[cupoId] = [];
											}
											accum[cupoId].push(servicio);
										});
									}
									return accum;
								}, {});
							this.tiposGruposViajes = res.tipos;
						}),
						ViajesGrupoApiService.getFiltroServicios(viajeId).then(res => {
							this.filtros = res;
						}),
					]);
				} finally {
					this.loading = false;
				}
			} else if (forceReloadServicio) {
				this.loading = true;
				try {
					await ViajesGrupoApiService.getServicios(viajeId).then(res => {
						this.serviciosCategorizado = res.serviciosCategorizados;
						this._cuposMetaServicios = Object.values(this.serviciosCategorizado)
							.reduce((accum, sc) => accum.concat(sc.servicios), [])
							.reduce((accum, servicio) => {
								if (!servicio.nulo) {
									servicio.cuposMetasId.forEach(cupoId => {
										if (accum[cupoId] === undefined) {
											accum[cupoId] = [];
										}
										accum[cupoId].push(servicio);
									});
								}
								return accum;
							}, {});
						this.tiposGruposViajes = res.tipos;
					});
				} finally {
					this.loading = false;
				}
			}
		},
		setInformacionAlojamientoFromVGAlojamientoReservaDTO(vgAlojamientoReservaDTO) {
			this.informacionAlojamiento = {};
			this.informacionAlojamiento.estrellas = vgAlojamientoReservaDTO.estrellas;
			this.informacionAlojamiento.incremento = vgAlojamientoReservaDTO.incremento || 0;
			this.informacionAlojamiento.nombre = vgAlojamientoReservaDTO.nombre;
			this.informacionAlojamiento.poblacion = vgAlojamientoReservaDTO.poblacion;
			this.informacionAlojamiento.precioCamaVacia = vgAlojamientoReservaDTO.precioCamaVacia;
			this.informacionAlojamiento.tasaTuristica = vgAlojamientoReservaDTO.tasaTuristica;
			this.informacionAlojamiento.habitacion = vgAlojamientoReservaDTO.habitacion;
			this.informacionAlojamiento.hasHabitaciones = vgAlojamientoReservaDTO.hasHabitaciones;
			this.informacionAlojamiento.codigoSinHabitacion = vgAlojamientoReservaDTO.codigoSinHabitacion;
		},
		setInformacionAlojamientoFromRGrupoDTO(rGrupoDTO) {
			if (rGrupoDTO.habitacion) {
				this.informacionAlojamiento = {};
				this.informacionAlojamiento.estrellas = rGrupoDTO.habitacion.alojamiento.estrellas;
				this.informacionAlojamiento.incremento = rGrupoDTO.habitacion.alojamiento.incremento || 0;
				this.informacionAlojamiento.nombre = rGrupoDTO.habitacion.alojamiento.nombre;
				this.informacionAlojamiento.poblacion = rGrupoDTO.habitacion.alojamiento.poblacion;
				this.informacionAlojamiento.precioCamaVacia = rGrupoDTO.habitacion.alojamiento.precioCamaVacia;
				this.informacionAlojamiento.tasaTuristica = rGrupoDTO.habitacion.alojamiento.tasaTuristica;
				this.informacionAlojamiento.hasHabitaciones = !rGrupoDTO.habitacion.isComun;
				this.informacionAlojamiento.codigoSinHabitacion = rGrupoDTO.codigo;
				this.informacionAlojamiento.habitacion = {
					tipo: {
						nombre: rGrupoDTO.habitacion.alojamiento.tipo,
					},
					ocupantes: rGrupoDTO.ocupantesNoGestionados,
					...rGrupoDTO.habitacion,
				};
			} else {
				this.clearInformacionAlojamiento();
			}
		},
		// todo@jorge: evaluar si tiene sentido este metodo o se deberia utilizar otro a pesar de necesitar una llamada extra a la API
		setInformacionAlojamientoFromVGAlojamientoViajeDTO(vgAlojamientoViajeDTO) {
			const cupo = vgAlojamientoViajeDTO.cupos[0];
			this.informacionAlojamiento = vgAlojamientoViajeDTO;
			this.informacionAlojamiento.incremento = vgAlojamientoViajeDTO.incremento || 0;
			this.informacionAlojamiento.hasHabitaciones = cupo.hasHabitaciones;
			this.informacionAlojamiento.habitacion = {
				numPlazas: cupo.plazas,
				numPlazasOcupadas: 0,
				plazasLibresRepercutidas: false,
				ocupantes: [],
				...cupo,
			};
		},
		clearInformacionAlojamiento() {
			this.informacionAlojamiento = null;
		},
		setInformacionGrupoFromVGGrupoDTO(vgGrupoDTO) {
			this.informacionGrupo = vgGrupoDTO;
		},
		clearReservas() {
			this.reservas = [];
		},
		addReserva(reservaDto) {
			let reserva = {
				persisted: true,
				...reservaDto,
			};
			let serviciosByTipo = {};
			reservaDto.serviciosWithCantidad.forEach(swc => {
				const servicioPB = getPaqueteBasicoForServicio(this.serviciosCategorizado, swc.servicio);
				serviciosByTipo[getServicioUniqueId(swc.servicio)] = {
					categoriaPaqueteBasico: servicioPB !== null,
					servicioPB: servicioPB,
					...swc,
				};
			});
			reserva.usuario = {
				...reservaDto.usuario,
				// todo-> Use apellidos instead of apellido
				apellido: reservaDto.usuario.apellidos,
			};
			// The servicios needs to be index to avoid duplicates
			reserva.serviciosWithCantidad = serviciosByTipo;
			this.reservas.push(reserva);
		},
		updateIsMenorReserva(reservaIdx, isMenor) {
			this.reservas[reservaIdx].usuario.isMenor = isMenor;
		},
		initOldServicios(reservaId) {
			const index = this.reservas.findIndex(reserva => reserva.id === reservaId);
			if (index !== -1) {
				this.reservas[index].oldServiciosWithCantidad = toRawDeep(this.reservas[index].serviciosWithCantidad);
				this.reservas[index].deltaCambioTmp = currency(0);
			}
		},
		//Compara los servicios antiguos con los nuevos y calcula la Tasa de Gestion de Cambio
		updateTasaCambio(idxReserva) {
			const tasaTotal = this.getFeeTotalPlataforma;
			const reserva = this.reservas[idxReserva];
			if (reserva.oldServiciosWithCantidad && reserva.serviciosWithCantidad) {
				let sumatorioCambioNew = currency(0);
				Object.keys(reserva.serviciosWithCantidad).forEach(servicioKey => {
					const newServicioWithCantidad = reserva.serviciosWithCantidad[servicioKey];
					const newServicio = newServicioWithCantidad.servicio;
					const oldServicioWithCantidad = reserva.oldServiciosWithCantidad[servicioKey] || {
						servicio: { id: null, precio: currency(0) },
						cantidad: 0,
					};
					const oldServicio = oldServicioWithCantidad.servicio;

					if (
						newServicio.id === oldServicio.id &&
						newServicioWithCantidad.cantidad === oldServicioWithCantidad.cantidad
					) {
						reserva.deltaCambioTmp = currency(0);
						return;
					}
					const newPrecio = calculaPrecioForServicioWithCantidad(newServicioWithCantidad);
					const oldPrecio = calculaPrecioForServicioWithCantidad(oldServicioWithCantidad);
					const deltaPrecio = Math.abs(newPrecio.subtract(oldPrecio).value);
					if (deltaPrecio > 0) {
						sumatorioCambioNew = sumatorioCambioNew.add(currency(deltaPrecio).multiply(tasaTotal));
						//todo @ramon -> descomentar cuando se libere la funcionalidad en el back
						//sumatorioCambioNew = sumatorioCambioNew.add(Math.max(0.2, currency(deltaPrecio).multiply(tasaTotal).value));
					}
				});

				if (sumatorioCambioNew.value > 0) {
					reserva.deltaCambioTmp = sumatorioCambioNew;
				}
			}
		},
		addServicioToReserva(idxReserva, servicio, cantidad = 1, isModificacion = false) {
			const reserva = this.reservas[idxReserva];
			const servicioPB = getPaqueteBasicoForServicio(this.serviciosCategorizado, servicio);
			reserva.serviciosWithCantidad[getServicioUniqueId(servicio)] = {
				servicio: servicio,
				cantidad: cantidad,
				categoriaPaqueteBasico: servicioPB !== null,
				servicioPB: servicioPB,
			};
			if (isModificacion && reserva.estado !== ESTADOS_RESERVA.BLOQUEADA) {
				this.updateTasaCambio(idxReserva);
			}
		},
		incrementCupoServicios(servicio, incremento = 1) {
			if (servicio && !servicio.nulo) {
				servicio.cuposMetasId.forEach(cupoId => {
					this._cuposMetaServicios[cupoId].forEach(s => (s.numCupos += incremento));
				});
			}
		},
		decrementCupoServicios(servicio, decremento = 1) {
			if (servicio && !servicio.nulo) {
				servicio.cuposMetasId.forEach(cupoId => {
					this._cuposMetaServicios[cupoId].forEach(s => (s.numCupos -= decremento));
				});
			}
		},
	},
});
