<template>
	<teleport to="body">
		<modal-base
			:show="modalActive"
			:title="title"
			hide-submit
			hide-cancel
			class="modal-confirmacion"
			@on-close="emits('close-modal')">
			<template #body>
				<p class="text-left mb-4">{{ message }}</p>

				<div class="text-center m-2">
					<button type="button" class="btn btn-red me-2" @click="handleConfirm">
						{{ confirmText }}
					</button>
					<button type="button" class="btn btn-primary" @click="emits('close-modal')">
						{{ cancelText }}
					</button>
				</div>
			</template>
		</modal-base>
	</teleport>
</template>

<script setup>
	import ModalBase from '@/components/modales/ModalBase';

	defineProps({
		modalActive: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			required: true,
		},
		message: {
			type: String,
			required: true,
		},
		confirmText: {
			type: String,
			default: 'Aceptar',
		},
		cancelText: {
			type: String,
			default: 'Cancelar',
		},
	});

	const emits = defineEmits(['close-modal', 'confirm']);

	function handleConfirm() {
		emits('confirm');
		emits('close-modal');
	}
</script>

<style scoped lang="scss">
	.btn-primary {
		font-size: 14px;
		font-weight: 500;
	}

	.btn-link {
		font-size: 14px;
		font-weight: 500;
		text-decoration: none;
	}
</style>
