import axios from 'axios';
import { parseJwt } from '../utils/CookiesUtils';

export default class BaseApiService {
	constructor(config) {
		this.axioInstance = axios.create(config);
	}

	get(url, queryParams, headers, responseType) {
		return this.axioInstance.get(url, this._createRequestConfig(queryParams, headers, responseType));
	}

	async post(url, data, queryParams, headers, responseType) {
		// eslint-disable-next-line no-useless-catch
		try {
			const res = await this.axioInstance.post(
				url,
				data,
				this._createRequestConfig(queryParams, headers, responseType)
			);
			return res;
		} catch (error) {
			throw error;
		}
	}

	put(url, data, queryParams, headers, responseType) {
		return this.axioInstance.put(url, data, this._createRequestConfig(queryParams, headers, responseType));
	}

	patch(url, data, queryParams, headers, responseType) {
		return this.axioInstance.patch(url, data, this._createRequestConfig(queryParams, headers, responseType));
	}

	delete(url, queryParams = {}, headers = {}, responseType) {
		return this.axioInstance.delete(url, this._createRequestConfig(queryParams, headers, responseType));
	}

	_createRequestConfig(
		queryParams = {},
		headers = {
			Accept: 'application/json, text/plain',
			'Content-Type': 'application/json;charset=UTF-8',
		},
		responseType = 'json'
	) {
		// AxiosRequestConfig
		const jwt = localStorage.getItem('jwt-mg');
		let paramsHeader = {
			params: queryParams,
			headers: headers,
			responseType: responseType,
		};
		if (jwt) {
			if (new Date(parseJwt(jwt).exp * 1000) > new Date()) {
				paramsHeader.headers['Authorization'] = 'Bearer ' + jwt;
			} else {
				localStorage.removeItem('jwt-mg');
			}
		}
		return paramsHeader;
	}
}
