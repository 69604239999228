export const sanitizeHtml = {
	beforeMount(el, binding) {
		sanitize(el, binding.value);
	},
	updated(el, binding) {
		sanitize(el, binding.value);
	},
};

function sanitize(el, html) {
	if (!html) return;

	const parser = new DOMParser();
	const doc = parser.parseFromString(html, 'text/html');

	const scripts = doc.querySelectorAll('script');
	scripts.forEach(script => script.remove());

	el.innerHTML = doc.body.innerHTML;
}
