import ReservasApiService from '@/services/api/skiandnight/ReservasApiService';
import i18n from '@/i18n';

const { t } = i18n.global;

export 	async function realizarCheckIn(formulariosInscripcionReservas, toaster, grupoId, instant) {
	try {
		const requests = [];
		for (const formulario of formulariosInscripcionReservas) {
			let error = false;
			if (formulario.checkInPendiente && (!instant || formulario.checkInInstant)) {
				const formDataDocumentacion = formDataDocumentacionValidator(formulario);
				if (formDataDocumentacion === null) {
					error = true;
				} else {
					requests.push(
						() => ReservasApiService.postFormularioCheckIn(grupoId, formulario.id, formDataDocumentacion)
					);
				}
			}
			if (
				formulario.datosPersonalesPendiente && formulario.datosPersonalesEnabled
				&& (!instant || formulario.datosPersonalesInstant)
			) {
				const formDataPersonal = formDataPersonalValidator(formulario);
				if (formDataPersonal === null) {
					error = true;
				} else {
					requests.push(
						() => ReservasApiService.postFormularioDatosPersonales(grupoId, formulario.id, formDataPersonal),
					);
				}
			}
			if (formulario.referidosPendiente && formulario.referidosEnabled) {
				const formDataReferidos = formDataReferidosValidator(formulario);
				if (formDataReferidos === null) {
					error = true;
				} else {
					requests.push(
						() => ReservasApiService.postFormularioReferidos(grupoId, formulario.id, formDataReferidos),
					);
				}
			}
			if (formulario.preguntasPendiente) {
				const formDataPreguntas = formDataPreguntasValidator(formulario);
				if (formDataPreguntas === null) {
					error = true;
				} else {
					requests.push(
						() => ReservasApiService.postFormularioPreguntas(grupoId, formulario.id, formDataPreguntas)
					);
				}
			}
			if (error) {
				toaster.error(t('reserva.checkIn.msg.required'));
				return false;
			}
		}
		await Promise.all(requests.map(request => request()));
		toaster.success(t('reserva.checkIn.msg.ok'));
		return true;
	} catch (error) {
		console.error('Error al realizar el check-in o enviar datos personales:', error);
		toaster.error(t('reserva.checkIn.msg.error'));
		return false;
	}
}

function formDataReferidosValidator(formulariosInscripcionReserva) {
	const formDataReferidos = formulariosInscripcionReserva.formDataReferidos;
	if (
		(
			formDataReferidos.conociste === null
			|| (formDataReferidos.conociste === 'comerciales' && formDataReferidos.comercial === null)
		)
		|| (
			formDataReferidos.estudiante === null
			|| (formDataReferidos.estudiante && formDataReferidos.universidad === null)
			|| (!formDataReferidos.estudiante && formDataReferidos.trabajo === null)
		)
	) {
		return null;
	}
	return formDataReferidos;
}

function formDataPreguntasValidator(formulariosInscripcionReserva) {
	const formDataPreguntas = formulariosInscripcionReserva.formDataPreguntas;
	if (!formDataPreguntas.preguntas.every(pregunta => pregunta.respuesta !== null)) {
		return null;
	}
	return formDataPreguntas;
}

function formDataDocumentacionValidator(formulariosInscripcionReserva) {
	const formDataDocumentacion = formulariosInscripcionReserva.formDataDocumentacion;
	for (const [key, value] of Object.entries(formDataDocumentacion)) {
		if (
			((key !== 'anversoDni' && key !== 'reversoDni') || !formulariosInscripcionReserva.dniSubido)
			&& value === null
		) {
			return null;
		}
	}
	const formData = new FormData();
	formData.append('nacionalidad', formDataDocumentacion.nacionalidad);
	formData.append('fechaNacimiento', formDataDocumentacion.fechaNacimiento);
	formData.append('lugarNacimiento', formDataDocumentacion.lugarNacimiento);
	formData.append('dni', formDataDocumentacion.dni);
	formData.append('fechaCaducidadDni', formDataDocumentacion.fechaCaducidadDni);
	formData.append('fechaExpedicionDni', formDataDocumentacion.fechaExpedicionDni);
	formData.append('numSoporteDni', formDataDocumentacion.numSoporteDni);
	formData.append('sexo', formDataDocumentacion.sexo);
	formData.append('direccion', formDataDocumentacion.direccion);
	formData.append('codigoPostal', formDataDocumentacion.codigoPostal);
	formData.append('pais', formDataDocumentacion.pais);
	formData.append('horaCheckIn', formDataDocumentacion.horaCheckIn);
	formData.append('horaCheckOut', formDataDocumentacion.horaCheckOut);

	if (formDataDocumentacion.anversoDni) {
		formData.append('anversoDni', formDataDocumentacion.anversoDni);
	}
	if (formDataDocumentacion.reversoDni) {
		formData.append('reversoDni', formDataDocumentacion.reversoDni);
	}
	return formData;
}

function formDataPersonalValidator(formulariosInscripcionReserva) {
	const formDataPersonal = formulariosInscripcionReserva.formDataPersonal;
	for (const [key, value] of Object.entries(formDataPersonal)) {
		if (
			((key === 'alergias') || formulariosInscripcionReserva.hasAlquiler)
			&& value === null
		) {
			return null;
		}
	}
	return formDataPersonal;
}
