<template>
	<div
		ref="scrollContainer"
		class="row-occupancy"
		:class="[
			{ 'justify-content-center text-center align-items-center': embeddedDisplay },
			{ 'seleccion-servicios': isCardMode },
			{ 'multi-line': isCardMode && !isInlineMode },
		]"
		@mousedown="e => startDragging(e, isInlineMode)"
		@mousemove="e => onDrag(e, isInlineMode)"
		@mouseup="() => stopDragging(isInlineMode)"
		@mouseleave="() => stopDragging(isInlineMode)"
		@touchstart="startDragging"
		@touchmove="onDrag"
		@touchend="stopDragging">
		<i
			v-if="isCardMode && showArrows && isInlineMode"
			class="fa-solid fa-chevron-left flechas pointer"
			@click="handleScrollLeft">
		</i>

		<div
			v-for="(user, index) in allOccupants"
			:key="`user-${index}`"
			class="d-flex flex-column align-items-center text-center occupancy-item"
			:class="{
				'small-icon-occupancy': embeddedDisplay,
				'occupancy-card col-md-4 col-4': embeddedDisplay,
				'occupancy col': !embeddedDisplay,
				'seleccion-servicios-item': isCardMode,
			}">
			<div
				v-if="user.isFree"
				class="d-flex flex-column text-center p-0 m-0"
				:class="{
					'small-icon-occupancy': embeddedDisplay,
					'occupancy-card col-md-4 col-4': embeddedDisplay,
					occupancy: !embeddedDisplay,
					pointer: enablePointer,
				}"
				@click="handleAddReserva">
				<IconFree :plazas-libres-repercutidas="plazasLibresRepercutidas" />
				<div class="nombre-occupant text-nowrap mx-2" :class="{ 'small-text': embeddedDisplay }">
					{{ $t('reserva.visualizacionOcupantes.plaza') }}
					<br v-if="!isCardMode" />
					{{ $t('reserva.visualizacionOcupantes.libre') }}
				</div>
			</div>
			<template v-else>
				<IconOccupied :user="user" :all-colors="allColors" />
				<div class="nombre-occupant text-nowrap mx-2" :class="{ 'small-text': embeddedDisplay }">
					{{ truncateText(user.nombre) }}
					<br v-if="!isCardMode" />
					{{ truncateText(user.apellido) }}
				</div>
				<div v-if="user.id" class="nombre-occupant text-nowrap mx-2" :class="{ 'small-text': embeddedDisplay }">
					(#{{ user.id }})
				</div>
			</template>
		</div>
		<i
			v-if="isCardMode && showArrows && isInlineMode"
			class="fa-solid fa-chevron-right flechas pointer"
			@click="handleScrollRight">
		</i>
	</div>
</template>

<script setup>
	import { useI18n } from 'vue-i18n';
	import { computed, onMounted } from 'vue';
	import IconFree from 'src/components/Icons/IconFree.vue';
	import IconOccupied from 'src/components/Icons/IconOccupied.vue';
	import { useDraggableScroll, useScrollableContainer } from '@/helpers/gestureUtils';

	const emit = defineEmits(['addReserva']);

	const { t } = useI18n();
	const props = defineProps({
		totalOccupancies: {
			type: Number,
			default: -1,
			required: false,
		},
		occupantsInfo: {
			type: Array,
			default: () => [],
		},
		allColors: {
			type: Boolean,
			default: false,
		},
		plazasLibresRepercutidas: {
			type: Boolean,
			default: false,
		},
		embeddedDisplay: {
			type: Boolean,
			default: false,
		},
		enablePointer: {
			type: Boolean,
			default: false,
		},
		isCardMode: {
			type: Boolean,
			default: false,
		},
		isInlineMode: {
			type: Boolean,
			default: true,
		},
	});

	const { startDragging, onDrag, stopDragging } = useDraggableScroll();
	const { scrollContainer, showArrows, handleScrollLeft, handleScrollRight, setupScrollableContainer } =
		useScrollableContainer();

	const hasHabs = computed(() => props.totalOccupancies >= 0);

	const managedOccupants = computed(() => {
		return props.occupantsInfo.map((user, index) => ({
			nombre: user?.nombre || (hasHabs.value ? t('reserva.visualizacionOcupantes.persona') : t('input.field.nombre')),
			apellido:
				user?.apellido || (hasHabs.value ? `${index + 1}/${props.totalOccupancies}` : t('input.field.apellido')),
			done: user?.done,
			isFree: user?.nombre === t('reserva.visualizacionOcupantes.persona'),
			id: user?.id || '',
		}));
	});

	const nonManagedOccupants = computed(() => {
		if (hasHabs.value) {
			const remaining = props.totalOccupancies - managedOccupants.value.length;
			return Array.from({ length: remaining }, () => ({
				nombre: '',
				apellido: '',
				done: false,
				isFree: true,
				freeOccupancy: true,
			}));
		} else {
			return [];
		}
	});

	const allOccupants = computed(() => {
		return [...managedOccupants.value, ...nonManagedOccupants.value];
	});

	function truncateText(text, maxLength = 8) {
		const formattedText = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
		return formattedText.length > maxLength ? `${formattedText.substring(0, maxLength)}...` : formattedText;
	}

	function handleAddReserva() {
		emit('addReserva');
	}

	onMounted(() => {
		setupScrollableContainer({
			source: allOccupants,
			enabled: props.isCardMode,
			isInlineMode: props.isInlineMode,
		});
	});
</script>
<style lang="scss" scoped>
	@import '~@/assets/scss/san.scss';

	.row-occupancy {
		&:not(.seleccion-servicios) {
			padding: 5px 3px 0 3px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			max-width: 900px;
			overflow: hidden;
			@include media-breakpoint-down(lg) {
				padding-bottom: 10px;
			}
			user-select: none;
		}

		&.seleccion-servicios {
			padding: 5px 16px;
			height: 54px;
			width: 100%;
			overflow-x: auto;
			display: flex;
			flex-wrap: nowrap;
			user-select: none;
			position: relative;
		}
	}

	.occupancy {
		&:not(.seleccion-servicios-item) {
			@extend .d-flex, .align-items-center, .text-center;
			width: 12.5%;
			@include media-breakpoint-down(md) {
				width: 100%;
				font-size: 12px;
				margin-right: 10px;
			}
			font-size: 18px;

			margin: 0.5rem;
		}

		&.seleccion-servicios-item {
			flex: 0 0 auto;
			margin: 0 10px;

			&:last-child {
				margin-right: 0 !important;
			}
		}
	}

	.occupancy:nth-child(n + 9) {
		display: none;
	}

	.nombre-occupant {
		padding-top: 5px;
		color: var(--bs-primary);
		font-size: 14px;
		font-weight: 400;

		@include media-breakpoint-down(lg) {
			font-size: 10px;
		}
	}
	.icon-inactive {
		transition: 0.3s;
	}

	.icon-active {
		transform: rotate(180deg);
		transition: 0.3s;
	}
	.small-icon-occupancy {
		.icono-ocupado,
		.icon-occupant-active,
		.icono-libre {
			width: 40px !important;
			height: 40px !important;
			font-size: 12px;
			place-self: center;
		}
	}

	.small-text {
		font-size: 12px;
		place-self: center;
	}

	.text-center {
		text-align: -webkit-center !important;
	}

	.seleccion-servicios {
		background-color: #f9f7f8;
		border-radius: var(--bs-border-radius-lg);
		padding: 5px 16px;
		height: 54px;
		width: 100%;
		overflow-x: auto;
		display: flex;
		flex-wrap: nowrap;
		user-select: none;
		position: relative;

		&:active {
			cursor: grabbing;
		}

		&::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none;
		scrollbar-width: none;

		.seleccion-servicios-item {
			flex: 0 0 auto;
			min-width: fit-content;
			flex-direction: row !important;
			align-items: center !important;
			display: inline-flex;
			margin: 0 10px;

			&:last-child {
				margin-right: 0 !important;
			}

			.icono-ocupado,
			.icono-libre {
				width: 24px !important;
				height: 24px !important;

				font-size: 15px;
			}

			.nombre-occupant {
				text-align: left;
				margin-left: 8px;
			}

			> div {
				display: flex !important;
				align-items: center !important;
				flex-direction: row !important;
			}
		}

		.flechas {
			position: sticky;
			top: 50%;
			transform: translateY(-50%);
			font-size: 15px;
			padding: 0 10px;
			width: 24px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--bs-primary);
			text-shadow: 0 1px 1px rgba(var(--bs-secondary-rgb), 1);

			&:first-child {
				left: 0;
				margin-right: 0;
			}

			&:last-child {
				right: 0;
				margin-left: auto;
			}
		}
	}

	.multi-line {
		&.seleccion-servicios {
			height: auto !important;
			overflow: visible !important;
			display: flex !important;
			flex-wrap: wrap !important;
			gap: 8px !important;
			padding: 16px !important;

			.seleccion-servicios-item {
				flex: 0 0 auto !important;
				margin: 4px !important;
			}
		}

		&:active {
			cursor: inherit;
		}
	}
</style>
