<template>
	<div class="comunidad-info">
		<p class="mb-3">{{ $t('reserva.comunidad.pertenece') }}</p>
		<div class="comunidad-card p-4 rounded">
			<div class="d-flex justify-content-between align-items-center mb-3">
				<h4 class="comunidad-titulo m-0 text-truncate">{{ grupo.superGrupo.nombre }}</h4>
				<button v-if="grupo.superGrupo.creador" class="btn-eliminar ms-2" @click="$emit('eliminar')">
					{{ $t('actions.delete') }}
					<img src="@/assets/icons/trash.svg" alt="eliminar" class="ms-2" />
				</button>
			</div>

			<div class="link-compartir mb-4">
				<p class="compartir-texto mb-2">{{ $t('reserva.comunidad.compartirLink') }}</p>
				<div
					class="link-container align-items-center cursor-pointer"
					@click="$emit('copiar-codigo', grupo.superGrupo.codigo)">
					<span class="link-text p-1">{{ urlCompleta }}</span>
					<button class="btn-share pe-1">
						<img src="@/assets/icons/share-yellow.svg" alt="compartir" />
					</button>
				</div>
			</div>

			<div class="miembros">
				<p class="mb-2">
					{{ totalMiembros }}
					{{ totalMiembros === 1 ? t('reserva.comunidad.miembro') : t('reserva.comunidad.miembros') }}
				</p>
				<CardOccupancy
					:is-card-mode="true"
					:is-inline-mode="false"
					:total-occupancies="grupo.superGrupo.grupos.length"
					:occupants-info="transformedOccupants"
					class="occupancy-custom" />
			</div>
		</div>
	</div>
</template>

<script setup>
	import { useI18n } from 'vue-i18n';
	import { computed } from 'vue';
	import CardOccupancy from '@/modules/viajesGrupos/components/CardOccupancy.vue';

	const props = defineProps({
		grupo: {
			type: Object,
			required: true,
		},
	});

	defineEmits(['eliminar', 'copiar-codigo']);

	const { t } = useI18n();

	const transformedOccupants = computed(() => {
		const occupants = props.grupo.superGrupo.grupos.flatMap(grupo =>
			grupo.miembros.map(miembro => ({
				nombre: miembro.nombre,
				apellido: miembro.apellidos,
				done: true,
			}))
		);

		const plazasLibresTotales = props.grupo.superGrupo.grupos.reduce((total, grupo) => {
			return total + grupo.plazasLibres;
		}, 0);

		for (let i = 0; i < plazasLibresTotales; i++) {
			occupants.push({
				nombre: t('reserva.visualizacionOcupantes.persona'),
				apellido: '',
				done: false,
				isFree: true,
			});
		}

		return occupants;
	});

	const urlCompleta = computed(() => {
		const dominioActual = window.location.origin;
		return `${dominioActual}/comunidad/${props.grupo.id}/unirse/${props.grupo.superGrupo.codigo}`;
	});

	const totalMiembros = computed(() => {
		return props.grupo.superGrupo.grupos.reduce((total, grupo) => {
			return total + grupo.miembros.length;
		}, 0);
	});
</script>

<style scoped lang="scss">
	.comunidad-card {
		border: 1px solid var(--bs-border-color);
		background: white;
	}

	.comunidad-titulo {
		font-size: 20px;
		font-weight: 500;
	}

	.compartir-texto {
		font-size: 16px;
		font-weight: 400;
	}

	.link-container {
		display: inline-flex;
		background: rgba(var(--bs-secondary-rgb), 0.2);
		padding: 4px 6px;
		border-radius: var(--bs-border-radius-xxl);
		gap: 10px;
		height: 28px;
		max-width: 100%;
		overflow: hidden;
		cursor: pointer;

		&:hover {
			background: rgba(var(--bs-secondary-rgb), 0.4);
			transition: background-color 0.3s ease;
		}
	}

	.link-text {
		color: var(--bs-secondary);
		font-family: Montserrat;
		font-size: 16px;
		font-weight: 400;
		line-height: 19.5px;
		text-align: left;
		display: flex;
		align-items: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.btn-share {
		border: none;
		background: transparent;
		padding: 0;
		display: flex;
		align-items: center;
		height: 20px;
	}

	.link-compartir {
		.bg-light {
			background-color: #f8f9fa;
		}
	}

	.miembros {
		.avatar {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: var(--bs-primary);
			color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
		}

		.plaza-libre {
			font-size: 0.875rem;
			color: var(--bs-secondary);
		}
	}

	:deep(.icono-ocupado) {
		background-color: var(--bs-primary);
		border: 3px solid var(--bs-secondary);
		color: var(--bs-secondary);
	}

	@media screen and (max-width: 768px) {
		.comunidad-info {
			font-size: 13px;
		}

		.comunidad-titulo {
			font-size: 16px;
		}

		.compartir-texto {
			font-size: 13px;
		}

		.link-text {
			font-size: 13px;
		}
	}
</style>
